<template>
  
  <div id="app" class="bigclass">
    <div class="topclass">      
      <div class="imgclass" @click="SetCameraA('A')"  />
      <div class="btnarea">
        <div class="btn" @click="SetCameraArcFree('EMC1')" >第一人称视角</div>
        <div class="btn" @click="SetCameraArcFree('EMC2')" >弧形镜头</div>
      </div>
    </div>
    <div class="bottomclass">
      <!--
        <div class="leftclass"></div>
        <div class="rightclass">
          
            <HelloWorld msg="Welcome to Your Vue3333.js App"/>
          
          <MyBabylon />
        </div>
      -->
      <div class="autoclass">
        <MyBabylon :clickevent="SetCameraA" :showmodel2="EMC"/>
      </div>
    </div>
  </div>

</template>

<script> 
import MyBabylon from './components/AnPeiLongBabylon.vue'
//import MyBabylon from './components/MyFirstBabylon.vue'
//import HelloWorld from './components/HelloWorld.vue'
var _this;
export default {
  name: 'App',
  components: {
    //HelloWorld
    MyBabylon
  },
  data(){
    return {
      EMC:"EMC1111",
    }
  },
  created(){
    _this = this;
  },
  methods:{
    SetCameraA:function(str){ 
      console.log("主页面："+str);
    },
    SetCameraArcFree:function(str){ 
      this.EMC = str;
    },
  } 
}
</script>

<style>
html,body,#app {
  display:flex;
  width: 100%; height: 100%; 
  margin: 0;
  padding: 0;
}
.bigclass{
  display:flex; flex-direction: column;
  width: 100%; height: 100%;  
}
.topclass{display:flex;flex-direction: row; justify-content: flex-start; align-items: center;
  width: 100%; height: 50px; background-color: rgb(255, 255, 255);;
}
.btnarea{display:flex;flex-direction: row; justify-content: flex-end; align-items: center;
  width: calc( 100% - 100px ); height: 100%; 
}
.btn{display:flex;flex-direction: row; justify-content: center; align-items: center; color: rgb(150, 150, 150); font-size: 12px;
  width: 100px; height: 30px; border-radius: 30px; border: 1px rgb(187, 187, 187) solid ; margin-right: 30px; cursor: pointer;
}

.btn:hover{ background-color: rgb(43, 143, 250);  color: rgb(255, 255, 255); }
.bottomclass{display:flex;flex-direction: row; justify-content: flex-start; align-items: center;
  width: 100%; height: calc( 100% - 50px ) ; background-color: rgb(128, 4, 139);
}
.leftclass{display:flex;
  width: 100px; height: 100%; background-color: rgb(0, 0, 0);;
}
.rightclass{display:flex;
  width: calc( 100% - 100px ); height: 100%; background-color: rgb(231, 161, 11);
}
.autoclass{display:flex;
  width: 100%; height: 100%; background-color: rgb(158, 216, 255);}
.imgclass{
  width: 100px; height: 100%; background-image: url("./assets/logo2.jpg"); background-position: center; background-size: contain; background-repeat: no-repeat; background-color: white; 
}

</style>
