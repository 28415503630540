
 
import * as BABYLON from "babylonjs"
import * as BABYLONUI from "babylonjs/babylon.gui.min.js" 
import * as BABYLONLOAD from "babylonjs/babylonjs.loaders.min.js"
import * as earcut from "earcut"
window.earcut = earcut

import  {createTree,createPineTree}  from '/src/assets/3djs/treecreator.js'

// function  createGround(scene)
// {
//     var myGround = BABYLON.MeshBuilder.CreateGround("myGround", {width: 75, height: 75, subdivisions: 1}, scene);
    
//     var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
//     myMaterial.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色
    
//     //myMaterial.diffuseTexture = new BABYLON.Texture("https://www.babylonjs-playground.com/textures/grass.jpg", scene);
//     myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G7.jpg", scene);
//     myMaterial.diffuseTexture.uScale = 30.0;//垂直方向重复5次
//     myMaterial.diffuseTexture.vScale = 30.0;//水平方向重复5次
//     myGround.material = myMaterial;//mesh是之前创建的物体
//     return myGround;
// }


function  createGround(scene,engine)
{
    // var myGround = BABYLON.MeshBuilder.CreateGround("myGround", {width: 75, height: 75, subdivisions: 1}, scene);
     

    // var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    // myMaterial.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色
    
    // //myMaterial.diffuseTexture = new BABYLON.Texture("https://www.babylonjs-playground.com/textures/grass.jpg", scene);
    // myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G7.jpg", scene);
    // myMaterial.diffuseTexture.uScale = 30.0;//垂直方向重复5次
    // myMaterial.diffuseTexture.vScale = 30.0;//水平方向重复5次
    // myGround.material = myMaterial;//mesh是之前创建的物体


    const groundMat = new BABYLON.StandardMaterial("myGround");
    groundMat.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M35.jpg");
    groundMat.diffuseTexture.hasAlpha = true; 
    const myGround = BABYLON.MeshBuilder.CreateGround("myGround",{width: 150, height: 150, subdivisions: 1});
    myGround.material = groundMat;
    
    //large ground
    const largeGroundMat = new BABYLON.StandardMaterial("myGround");
    largeGroundMat.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/H5.jpg");
    largeGroundMat.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    largeGroundMat.diffuseTexture.vScale = 1.0;//水平方向重复5次
    
    const largeGround = BABYLON.MeshBuilder.CreateGroundFromHeightMap("myGround", "https://s.mitcs.cn/das/H1.jpg", 
        {width:150, height:150, subdivisions: 20, minHeight:0, maxHeight: 10});
    largeGround.material = largeGroundMat;
    //为了防止出现闪烁的情况
    largeGround.position.y = -0.01; 

    var spriteManagerTrees2 = new BABYLON.SpriteManager("treesManager", "https://s.mitcs.cn/das/T1.png", 2000, { width: 512, height: 1024 }, scene);
    const tree1 = new BABYLON.Sprite("tree", spriteManagerTrees2);
    // //注：树的高宽比例，应该跟cellSize的比例一致，避免失真
    tree1.width = 5;
    tree1.height = 5;
    tree1.position.y = 2;
    //tree1.angle = Math.PI/4;    // 翻转
    tree1.invertU = true; //vertical reflection
    tree1.invertV = false; //horizontal reflection

    // sprite.angle = Math.PI/4;    // 翻转
    // sprite.invertU = true; //vertical reflection
    // sprite.invertV = true; //horizontal reflection
    var spriteManagerTrees = new BABYLON.SpriteManager("treesManager", "https://s.mitcs.cn/das/dj2.png", 2000, 800, scene);
    for (var i = 0; i < 2000; i++) {
        var tree = new BABYLON.Sprite("tree", spriteManagerTrees);
        tree.width = 2;
        tree.height = 2;
        tree.position.x = Math.random() * 100 - 50;
        tree.position.z = Math.random() * 100 - 50;
        tree.isPickable = true;
        tree.position.y = 0.8;
    }

  
    // 骷髅头
    // BABYLON.SceneLoader.ImportMesh("", "", "https://s.mitcs.cn/das/obj/844.babylon", scene, function (newMeshes) {
    //     // Set the target of the camera to the first imported mesh
    //     //camera.target = newMeshes[0];
    //     newMeshes[0].position = new BABYLON.Vector3(5,20,10);
    // });

    // 3Dmax里的方球圆柱
    BABYLON.SceneLoader.ImportMesh("", "", "https://s.mitcs.cn/das/obj/851.babylon", scene, function (newMeshes) {
        // Set the target of the camera to the first imported mesh
        //camera.target = newMeshes[0];
        newMeshes[0].position = new BABYLON.Vector3(-50,-20,-10);
        newMeshes[1].position = new BABYLON.Vector3(-50,-20,-10);
        newMeshes[2].position = new BABYLON.Vector3(-50,-20,-10);
        newMeshes[0].material = groundMat;
    });
    

    let faceUV = [];
    faceUV[0] = new BABYLON.Vector4(0.5, 0.0, 0.75, 1.0);// 后面
    faceUV[1] = new BABYLON.Vector4(0.0, 0.0, 0.25, 1.0);// 前面
    faceUV[2] = new BABYLON.Vector4(0.25, 0, 0.5, 1.0);// 右面
    faceUV[3] = new BABYLON.Vector4(0.75, 0, 1.0, 1.0);// 左面
    faceUV[4] = new BABYLON.Vector4(0, 0, 0, 0);// 上?
    faceUV[5] = new BABYLON.Vector4(0, 0, 0, 0);// 上?

    var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {width:10, height:5, depth:5,faceUV: faceUV}, scene);
    let boxMat = new BABYLON.StandardMaterial("boxMat");
    boxMat.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/H1.png")
    skybox.material = boxMat;
    skybox.position = new BABYLON.Vector3(0,10,60);


    // PBR材质    
    var skybox2 = BABYLON.MeshBuilder.CreateBox("skyBox", {width:10, height:5, depth:5,faceUV: faceUV}, scene);
    var pbr = new BABYLON.PBRMetallicRoughnessMaterial("pbr", scene);
    skybox2.material = pbr;

    pbr.baseColor = new BABYLON.Color3(1.0, 0.766, 0.336);
    pbr.metallic = 0.8;
    pbr.roughness = 0.0;
    pbr.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("https://s.mitcs.cn/das/environment.dds", scene);
    pbr.metallicRoughnessTexture = new BABYLON.Texture("https://s.mitcs.cn/das/mr.jpg", scene);
    skybox2.position = new BABYLON.Vector3(20,10,40);


    //  创建 一辆车
    createCar(scene);
    
    // // 创建树
    createMyTree2(scene);
    
    // // 创建松树
    // createPineTree(scene);

    // 动态辉光
    createGlowLayer(scene);


    return largeGround;//myGround;
}

function createGlowLayer(scene)
{
    var path = [new BABYLON.Vector3(-10,15,-5)];
    // 中间大弯曲长度    
    let sx = path[path.length-1].x;
    let sy = path[path.length-1].y; 
    let sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(10, sy, sz)); 
    let radius = 0.5;
    let r = 2
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    let da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx +r * Math.sin(da*i);
        let y = sy ;
        let z = sz  - (r - r * Math.cos( da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy, sz-10)); 

    var myPipe2=BABYLON.MeshBuilder.CreateTube('tube',{path:path ,radius :radius,tessellation :32,arc:1,cap:BABYLON.Mesh.CAP_START,updatable:true,sideOrientation:2},scene);
    
    var material = new BABYLON.StandardMaterial("mat", scene);
    material.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M43.jpg", scene);
    material.diffuseTexture.uScale = 1;
    material.diffuseTexture.vScale = 5;
    //material.emissiveColor = new BABYLON.Color3(245/255, 20/255, 20/255); 
    myPipe2.material = material;
    
    var gl = new BABYLON.GlowLayer("glow", scene);
    gl.intensity = 0.1;

    var alpha = 0;
    scene.registerBeforeRender(function () {
        material.diffuseTexture.uOffset += 0.01; 
        material.diffuseTexture.vOffset -= 0.005; 
        //gl.intensity += Math.sin(alpha*2) / 100;
        alpha += 0.01;
    });


    //上面是第一根管
    // 第二跟管
    var myPipe3 = myPipe2.clone("");
    myPipe3.position = new BABYLON.Vector3(myPipe3.position.x+3,myPipe3.position.y+3,myPipe3.position.z+3);
    var material3 = new BABYLON.StandardMaterial("mat", scene);
    material3.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M47.jpg", scene);
    material3.diffuseTexture.uScale = 1;
    material3.diffuseTexture.vScale = 10;
    //material.emissiveColor = new BABYLON.Color3(245/255, 20/255, 20/255); 
    myPipe3.material = material3;
    scene.registerBeforeRender(function () {
        material3.diffuseTexture.uOffset += 0.01; 
        material3.diffuseTexture.vOffset -= 0.02; 
    });
}

function crateNationalFlag(scene)
{
     // 添加旗杆
     var mymesh = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 20, diameter: 0.4,tessellation:20, subdivisions:20}, scene);
     mymesh.position = new BABYLON.Vector3(0, 0, 0);
     var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
     myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M14.jpg", scene);
     myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
     myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
     mymesh.material = myMaterial;//mesh是之前创建的物体
 
     //4、旗杆上方的球
     var mymesh420 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:0.7}, scene);
     mymesh420.position = new BABYLON.Vector3(0, 10, 0);
     mymesh420.material = myMaterial;
 
    // 台子
     var mymesh18 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 2, width: 10, depth: 5}, scene);
     mymesh18.position = new BABYLON.Vector3(0, -10, 0);
     mymesh18.material = myMaterial;
 
     
     // 旗子1
     //  var ground = BABYLON.MeshBuilder.CreatePlane("plane", {width:100, height: 200, updatable: true,sideOrientation:2 });
     var ground = BABYLON.MeshBuilder.CreateGround("ground", {width:2.5, height: 5, subdivisionsY: 15, subdivisionsX: 5, updatable: true,sideOrientation:1 }, scene); 
     var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
     myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/CnFlag3.jpg", scene);    
     ground.material = myMaterialS;
     ground.rotation.z = -Math.PI/2;
     ground.position = new BABYLON.Vector3(0, 8, 2.5);
 
     
     // 旗子2
     var banner = ground.clone("box" +0); // clone, cuz... needs adt material.
     banner.position = new BABYLON.Vector3(-0.03, 8, 2.5);
     banner.material = myMaterialS;
     banner.adt = BABYLONUI.AdvancedDynamicTexture.CreateForMesh(banner, 600, 300, false);
     var bottomBG = new BABYLONUI.Image("bottomBG", "https://s.mitcs.cn/das/CnFlag3.jpg");
     banner.adt.addControl(bottomBG);
 
      var positions = ground.getVerticesData(BABYLON.VertexBuffer.PositionKind);
     var indices = ground.getIndices();
     var randomNumber = function (min, max) {
         if (min == max) {
             return (min);
         }
         var random = Math.random();
         return ((random * (max - min)) + min);
     };
     console.log(positions);
     // Animations
     var alpha = 0;		
     scene.onBeforeRenderObservable.add(function(){
         var index, min = -1, max = 1;
         var len = positions.length;
         var beta = alpha;
         
         for(index = 0; index<len; index+=3) {
             positions[index+1] = Math.sin(beta)*(positions[index+2]+2.5)/8;
             beta += 0.1;
             // console.log(Math.sin(beta));
         }
         ground.updateVerticesData(BABYLON.VertexBuffer.PositionKind, positions, true, false);
         var normals = [];
         BABYLON.VertexData.ComputeNormals(positions, indices, normals);
         ground.updateVerticesData(BABYLON.VertexBuffer.NormalKind, normals, false, false);
         alpha += 0.1;
         
     });


     var Mesh = BABYLON.Mesh.MergeMeshes([mymesh , mymesh420 , mymesh18], true, true, undefined, true, true);
     let pos = new BABYLON.Vector3(40,15,10);
     Mesh.position = pos;
     ground.position = new BABYLON.Vector3(ground.position.x+pos.x,ground.position.y+pos.y,ground.position.z+pos.z);
     banner.position = new BABYLON.Vector3(banner.position.x+pos.x,banner.position.y+pos.y,banner.position.z+pos.z);

     crateNationalFlag2(scene);
     crateNationalFlag3(scene);
     return Mesh;
}


function crateNationalFlag2(scene)
{
     // 添加旗杆
     var mymesh = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 20, diameter: 0.4,tessellation:20, subdivisions:20}, scene);
     mymesh.position = new BABYLON.Vector3(0, 0, 0);
     var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
     myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M14.jpg", scene);
     myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
     myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
     mymesh.material = myMaterial;//mesh是之前创建的物体
 
     //4、旗杆上方的球
     var mymesh420 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:0.7}, scene);
     mymesh420.position = new BABYLON.Vector3(0, 10, 0);
     mymesh420.material = myMaterial;
 
    // // 台子
    //  var mymesh18 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 2, width: 10, depth: 5}, scene);
    //  mymesh18.position = new BABYLON.Vector3(0, -10, 0);
    //  mymesh18.material = myMaterial;
 
     
     // 旗子1
     //  var ground = BABYLON.MeshBuilder.CreatePlane("plane", {width:100, height: 200, updatable: true,sideOrientation:2 });
     var ground = BABYLON.MeshBuilder.CreateGround("ground", {width:2.5, height: 5, subdivisionsY: 15, subdivisionsX: 5, updatable: true,sideOrientation:1 }, scene); 
     var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
     myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/DasFlag3.jpg", scene);    
     ground.material = myMaterialS;
     ground.rotation.z = -Math.PI/2;
     ground.position = new BABYLON.Vector3(0, 8, 2.5);
 
     
     // 旗子2
     var banner = ground.clone("box" +0); // clone, cuz... needs adt material.
     banner.position = new BABYLON.Vector3(-0.03, 8, 2.5);
     banner.material = myMaterialS;
     banner.adt = BABYLONUI.AdvancedDynamicTexture.CreateForMesh(banner, 600, 300, false);
     var bottomBG = new BABYLONUI.Image("bottomBG", "https://s.mitcs.cn/das/DasFlag3.jpg");
     banner.adt.addControl(bottomBG);
 
      var positions = ground.getVerticesData(BABYLON.VertexBuffer.PositionKind);
     var indices = ground.getIndices();
     var randomNumber = function (min, max) {
         if (min == max) {
             return (min);
         }
         var random = Math.random();
         return ((random * (max - min)) + min);
     };
     console.log(positions);
     // Animations
     var alpha = 0;		
     scene.onBeforeRenderObservable.add(function(){
         var index, min = -1, max = 1;
         var len = positions.length;
         var beta = alpha;
         
         for(index = 0; index<len; index+=3) {
             positions[index+1] = Math.sin(beta)*(positions[index+2]+2.5)/8;
             beta += 0.1;
             // console.log(Math.sin(beta));
         }
         ground.updateVerticesData(BABYLON.VertexBuffer.PositionKind, positions, true, false);
         var normals = [];
         BABYLON.VertexData.ComputeNormals(positions, indices, normals);
         ground.updateVerticesData(BABYLON.VertexBuffer.NormalKind, normals, false, false);
         alpha += 0.1;
         
     });


     var Mesh = BABYLON.Mesh.MergeMeshes([mymesh , mymesh420 ], true, true, undefined, true, true);
     let pos = new BABYLON.Vector3(44,13,10);
     Mesh.position = pos;
     ground.position = new BABYLON.Vector3(ground.position.x+pos.x,ground.position.y+pos.y,ground.position.z+pos.z);
     banner.position = new BABYLON.Vector3(banner.position.x+pos.x,banner.position.y+pos.y,banner.position.z+pos.z);
     return Mesh;
}

function crateNationalFlag3(scene)
{
     // 添加旗杆
     var mymesh = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 20, diameter: 0.4,tessellation:20, subdivisions:20}, scene);
     mymesh.position = new BABYLON.Vector3(0, 0, 0);
     var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
     myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M14.jpg", scene);
     myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
     myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
     mymesh.material = myMaterial;//mesh是之前创建的物体
 
     //4、旗杆上方的球
     var mymesh420 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:0.7}, scene);
     mymesh420.position = new BABYLON.Vector3(0, 10, 0);
     mymesh420.material = myMaterial;
 
    // // 台子
    //  var mymesh18 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 2, width: 10, depth: 5}, scene);
    //  mymesh18.position = new BABYLON.Vector3(0, -10, 0);
    //  mymesh18.material = myMaterial;
 
     
     // 旗子1
     //  var ground = BABYLON.MeshBuilder.CreatePlane("plane", {width:100, height: 200, updatable: true,sideOrientation:2 });
     var ground = BABYLON.MeshBuilder.CreateGround("ground", {width:2.5, height: 5, subdivisionsY: 15, subdivisionsX: 5, updatable: true,sideOrientation:1 }, scene); 
     var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
     myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/EmcFlag3.jpg", scene);    
     ground.material = myMaterialS;
     ground.rotation.z = -Math.PI/2;
     ground.position = new BABYLON.Vector3(0, 8, 2.5);
 
     
     // 旗子2
     var banner = ground.clone("box" +0); // clone, cuz... needs adt material.
     banner.position = new BABYLON.Vector3(-0.03, 8, 2.5);
     banner.material = myMaterialS;
     banner.adt = BABYLONUI.AdvancedDynamicTexture.CreateForMesh(banner, 600, 300, false);
     var bottomBG = new BABYLONUI.Image("bottomBG", "https://s.mitcs.cn/das/EmcFlag3.jpg");
     banner.adt.addControl(bottomBG);
 
      var positions = ground.getVerticesData(BABYLON.VertexBuffer.PositionKind);
     var indices = ground.getIndices();
     var randomNumber = function (min, max) {
         if (min == max) {
             return (min);
         }
         var random = Math.random();
         return ((random * (max - min)) + min);
     };
     console.log(positions);
     // Animations
     var alpha = 0;		
     scene.onBeforeRenderObservable.add(function(){
         var index, min = -1, max = 1;
         var len = positions.length;
         var beta = alpha;
         
         for(index = 0; index<len; index+=3) {
             positions[index+1] = Math.sin(beta)*(positions[index+2]+2.5)/8;
             beta += 0.1;
             // console.log(Math.sin(beta));
         }
         ground.updateVerticesData(BABYLON.VertexBuffer.PositionKind, positions, true, false);
         var normals = [];
         BABYLON.VertexData.ComputeNormals(positions, indices, normals);
         ground.updateVerticesData(BABYLON.VertexBuffer.NormalKind, normals, false, false);
         alpha += 0.1;
         
     });


     var Mesh = BABYLON.Mesh.MergeMeshes([mymesh , mymesh420 ], true, true, undefined, true, true);
     let pos = new BABYLON.Vector3(36,12,10);
     Mesh.position = pos;
     ground.position = new BABYLON.Vector3(ground.position.x+pos.x,ground.position.y+pos.y,ground.position.z+pos.z);
     banner.position = new BABYLON.Vector3(banner.position.x+pos.x,banner.position.y+pos.y,banner.position.z+pos.z);
     return Mesh;
}

function createCar(scene)
{
    let faceUV2 = [];
    faceUV2[0] = new BABYLON.Vector4(0, 0.5, 0.38, 1);// top
    faceUV2[1] = new BABYLON.Vector4(0, 0, 1, 0.5);// bottom 
    faceUV2[2] = new BABYLON.Vector4(0.38, 1, 0, 0.5);// edge  
    //base
    let outline = [
        new BABYLON.Vector3(-0.3, 0, -0.1),
        new BABYLON.Vector3(0.2, 0, -0.1),
    ]
    //curved front
    for (let i = 0; i < 20; i++) {
        outline.push(new BABYLON.Vector3(0.2 * Math.cos(i * Math.PI / 40), 0, 0.2 * Math.sin(i * Math.PI / 40) - 0.1));
    }
    //top
    outline.push(new BABYLON.Vector3(0, 0, 0.1));
    outline.push(new BABYLON.Vector3(-0.3, 0, 0.1));
    var car = BABYLON.MeshBuilder.ExtrudePolygon("car", {shape: outline, depth: 0.2,faceUV: faceUV2});
    //let car = BABYLON.MeshBuilder.ExtrudePolygon("car", {shape: outline, depth: 0.2},scene);

    
    let faceUV3 = [];
    //faceUV3[0] = new BABYLON.Vector4(0, 0, 1, 1);// top
    faceUV3[0] = new BABYLON.Vector4(0.05, 0.05, 0.95, 0.95);// top
    faceUV3[1] = new BABYLON.Vector4(0, 0.5, 0, 0.5);// 侧边 
    faceUV3[2] = new BABYLON.Vector4(0.05, 0.05, 0.95, 0.95);// 下边  
    const wheelRB = BABYLON.MeshBuilder.CreateCylinder("wheelRB", {diameter: 0.125, height: 0.05, faceUV:faceUV3});
    wheelRB.parent = car;
    wheelRB.position.z = -0.1;
    wheelRB.position.x = -0.2;
    wheelRB.position.y = 0.035;
    let boxMat3 = new BABYLON.StandardMaterial("boxMat");
    boxMat3.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/wheel.png")
    wheelRB.material = boxMat3;

    const wheelRF = wheelRB.clone("wheelRF");
    wheelRF.position.x = 0.1;

    const wheelLB = wheelRB.clone("wheelLB");
    wheelLB.position.y = -0.2 - 0.035;

    const wheelLF = wheelRF.clone("wheelLF");
    wheelLF.position.y = -0.2 - 0.035;

    
    let boxMat2 = new BABYLON.StandardMaterial("boxMat");
    boxMat2.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/car.png")
    car.material = boxMat2;
    car.scaling = new BABYLON.Vector3(10,10,10);
    car.rotation.x = -Math.PI/2;
    car.position = new BABYLON.Vector3(0,15,60);

    const animWheel = new BABYLON.Animation("wheelAnimation", "rotation.y", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
    const wheelKeys = []; 
    wheelKeys.push({
        frame: 0,
        value: 0
    });
    wheelKeys.push({
        frame: 30,
        value: 2 * Math.PI
    });
    animWheel.setKeys(wheelKeys);
    wheelRB.animations = [];
    wheelRB.animations.push(animWheel);
    wheelRF.animations.push(animWheel);
    wheelLB.animations.push(animWheel);
    wheelLF.animations.push(animWheel);
    scene.beginAnimation(wheelRB, 0, 30, true);
    scene.beginAnimation(wheelRF, 0, 30, true);
    scene.beginAnimation(wheelLB, 0, 30, true);
    scene.beginAnimation(wheelLF, 0, 30, true);
}

function createMyTree(scene)
{
	//leaf material
	var green = new BABYLON.StandardMaterial("green", scene);
	green.diffuseColor = new BABYLON.Color3(0,1,0);	
	
	//trunk and branch material
	var bark = new BABYLON.StandardMaterial("bark", scene);
	bark.emissiveTexture = new BABYLON.Texture("https://s.mitcs.cn/das/Tree.jpg", scene);
	bark.diffuseTexture =  new BABYLON.Texture("https://s.mitcs.cn/das/Tree.jpg", scene);
	bark.diffuseTexture.uScale = 2.0;//Repeat 5 times on the Vertical Axes
	bark.diffuseTexture.vScale = 2.0;//Repeat 5 times on the Horizontal Axes		
	//Tree parameters			
	var trunk_height = 20;
    var trunk_taper = 0.6;
    var trunk_slices = 5;
    var boughs = 2; // 1 or 2
	var forks = 4;
	var fork_angle = Math.PI/4;
    var fork_ratio = 2/(1+Math.sqrt(5)); //PHI the golden ratio
	var branch_angle = Math.PI/3;
	var bow_freq = 2;
	var bow_height = 3.5;
	var branches = 5;
	var leaves_on_branch = 5;
    var leaf_wh_ratio = 0.5;
	var tree = createTree(trunk_height, trunk_taper, trunk_slices, bark, boughs, forks, fork_angle, fork_ratio, branches, branch_angle, bow_freq, bow_height, leaves_on_branch, leaf_wh_ratio, green, scene);				               
    tree.position.y = 10;
    tree.position.z = 50;
}

function createMyTree2(scene)
{
	//leaf material
	var green = new BABYLON.StandardMaterial("green", scene);
	green.diffuseColor = new BABYLON.Color3(0,1,0);	
	
	//trunk and branch material
	var bark = new BABYLON.StandardMaterial("bark", scene);
	bark.emissiveTexture = new BABYLON.Texture("https://s.mitcs.cn/das/Tree.jpg", scene);
	bark.diffuseTexture =  new BABYLON.Texture("https://s.mitcs.cn/das/Tree.jpg", scene);
	bark.diffuseTexture.uScale = 2.0;//Repeat 5 times on the Vertical Axes
	bark.diffuseTexture.vScale = 2.0;//Repeat 5 times on the Horizontal Axes		
	//Tree parameters			
	var trunk_height = 20;
    var trunk_taper = 0.5;
    var trunk_slices = 3;
    var boughs = 2; // 1 or 2
	var forks = 4;
	var fork_angle = Math.PI/4;
    var fork_ratio = 2/3; //PHI the golden ratio
	var branch_angle = Math.PI/6;
	var bow_freq = 2;
	var bow_height = 3;
	var branches = 3;
	var leaves_on_branch = 5;
    var leaf_wh_ratio = 0.5;
	var tree = createTree(trunk_height, trunk_taper, trunk_slices, bark, boughs, forks, fork_angle, fork_ratio, branches, branch_angle, bow_freq, bow_height, leaves_on_branch, leaf_wh_ratio, green, scene);				               
    tree.position.y = 0;
    tree.position.z = 20;
}

function  createSky1(scene)
{
	var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {size:1000.0}, scene);
	var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
	skyboxMaterial.backFaceCulling = false;
    var files = [
        "https://s.mitcs.cn/das/sky/space_left.jpg",
        "https://s.mitcs.cn/das/sky/space_up.jpg",
        "https://s.mitcs.cn/das/sky/space_front.jpg",
        "https://s.mitcs.cn/das/sky/space_right.jpg",
        "https://s.mitcs.cn/das/sky/space_down.jpg",
        "https://s.mitcs.cn/das/sky/space_back.jpg",
    ];
	skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture.CreateFromImages(files, scene);
	skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
	skyboxMaterial.disableLighting = true;
	skybox.material = skyboxMaterial;	
    return skybox;
}

function  createSky2(scene)
{
	var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {size:1000.0}, scene);
	var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
	skyboxMaterial.backFaceCulling = false;
	skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("https://s.mitcs.cn/das/sky/TropicalSunnyDay", scene);
	skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
	skyboxMaterial.disableLighting = true;
	skybox.material = skyboxMaterial;	

    // //雾的模式
    // scene.fogMode=BABYLON.Scene.FOGMODE_EXP2;
    // //雾的密度
    // scene.fogDensity=0.01;
    // //雾的开始和结束的位置
    // scene.fogStar=20;
    // scene.fogEnd=1200;
    // //雾的颜色
    // scene.fogColor=new BABYLON.Color3(0.8,0.8,0.8);
    return skybox;
}

function  createSky3(scene)
{
	var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {size:1000.0}, scene);
	var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
	skyboxMaterial.backFaceCulling = false;
	skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("https://s.mitcs.cn/das/sky/skybox3", scene);
	skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
	skyboxMaterial.disableLighting = true;
	skybox.material = skyboxMaterial;	
    return skybox;
}

// 地面围栏
function  createFence(scene)
{
    var myBox1 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 1, width: 75, depth: 0.5}, scene);
    myBox1.position = new BABYLON.Vector3(0, 0, -37.5);
    var myBox2 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 1, width: 75, depth: 0.5}, scene);
    myBox2.position = new BABYLON.Vector3(0, 0, 37.5);
    
    var myMaterialA = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialA.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialA.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G3.jpg", scene);
    myMaterialA.diffuseTexture.uScale = 40.0;//垂直方向重复5次
    myMaterialA.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myBox1.material = myMaterialA;//mesh是之前创建的物体
    myBox2.material = myMaterialA;//mesh是之前创建的物体


    
    var myBox3 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 1, width: 0.5, depth: 75}, scene);
    myBox3.position = new BABYLON.Vector3(-37.5, 0, 0);
    var myBox4 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 1, width: 0.5, depth: 75}, scene);
    myBox4.position = new BABYLON.Vector3(37.5, 0, 0);
    
    var myMaterialB = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialB.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialB.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G3.jpg", scene);
    myMaterialB.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterialB.diffuseTexture.vScale = 40.0;//水平方向重复5次
    myBox3.material = myMaterialB;//mesh是之前创建的物体
    myBox4.material = myMaterialB;//mesh是之前创建的物体

    var mymesh = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3,myBox4], true, true, undefined, false, true);
    mymesh.name = "MyFence";
    return mymesh;
}

// 下水道
function createDrainage(scene)
{
    // 1 最左边：竖
    var myBox1 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 0.02, width: 0.4, depth: 40}, scene);
    myBox1.position = new BABYLON.Vector3(22, 0, 15);     
    var myMaterialA = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialA.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialA.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G4.jpg", scene);
    myMaterialA.diffuseTexture.uScale = 20.0;//垂直方向重复5次
    myMaterialA.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myBox1.material = myMaterialA;//mesh是之前创建的物体  

    
    // 2 中间：竖
    var myBox2 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 0.02, width: 0.4, depth: 23}, scene);
    myBox2.position = new BABYLON.Vector3(0, 0, 23.5);     
    var myMaterialB = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialB.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialB.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G4.jpg", scene);
    myMaterialB.diffuseTexture.uScale = 11.5;//垂直方向重复5次
    myMaterialB.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myBox2.material = myMaterialB;//mesh是之前创建的物体  

    
    // 3 最右边：竖
    var myBox3 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 0.02, width: 0.4, depth: 17}, scene);
    myBox3.position = new BABYLON.Vector3(-22.5, 0, 3.5);     
    var myMaterialC = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialC.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialC.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G4.jpg", scene);
    myMaterialC.diffuseTexture.uScale = 8.5;//垂直方向重复5次
    myMaterialC.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myBox3.material = myMaterialC;//mesh是之前创建的物体  
    
    
    // 4 最底端：横
    var myBox4 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 0.02, width: 0.4, depth: 22-0.4}, scene);
    myBox4.rotation.y = Math.PI / 2;
    myBox4.position = new BABYLON.Vector3(11, 0, 35-0.2);     
    var myMaterialD = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialD.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialD.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G4.jpg", scene);
    myMaterialD.diffuseTexture.uScale = 11.0;//垂直方向重复5次
    myMaterialD.diffuseTexture.vScale = 1.0;//水平方向重复5次 
    myBox4.material = myMaterialA;//mesh是之前创建的物体  

    
    // 5 中间：横
    var myBox5 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 0.02, width: 0.4, depth: 22.5}, scene);
    myBox5.rotation.y = Math.PI / 2;
    myBox5.position = new BABYLON.Vector3(-11.25+0.2, 0, 12-0.2);     
    var myMaterialE = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialE.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialE.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G4.jpg", scene);
    myMaterialE.diffuseTexture.uScale = 11.0;//垂直方向重复5次
    myMaterialE.diffuseTexture.vScale = 1.0;//水平方向重复5次 
    myBox5.material = myMaterialE;//mesh是之前创建的物体  

    // 6 最底端：横
    var myBox6 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 0.02, width: 0.4, depth: 44.5-0.4}, scene);
    myBox6.rotation.y = Math.PI / 2;
    myBox6.position = new BABYLON.Vector3(-0.25, 0, -5+0.2);     
    var myMaterialF = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialF.diffuseColor = new BABYLON.Color4(1, 1, 1, 1);//漫反射颜色 
    myMaterialF.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G4.jpg", scene);
    myMaterialF.diffuseTexture.uScale = 11.0;//垂直方向重复5次
    myMaterialF.diffuseTexture.vScale = 1.0;//水平方向重复5次 
    myBox6.material = myMaterialF;//mesh是之前创建的物体  


    var mymesh = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3,myBox4,myBox5,myBox6], true, true, undefined, false, true);
    mymesh.name = "MyDrainage";
    return mymesh; 
}


// 冷水机组
function  createWaterChiller(scene)
{
    //1、底座
    var myBox1 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.3, width: 9.5, depth: 5}, scene);
    myBox1.position = new BABYLON.Vector3(0, 0.15, 0);
    
    var myMaterialA = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialA.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G1.jpg", scene); 
    myMaterialA.diffuseTexture.uScale = 2.0;//垂直方向重复5次
    myMaterialA.diffuseTexture.vScale = 3.0;//水平方向重复5次
    myBox1.material = myMaterialA;//mesh是之前创建的物体

    //2、垫片 
    var myBox2 = BABYLON.MeshBuilder.CreateBox("myBox2", {height: 0.1, width: 0.5, depth: 0.4}, scene);
    myBox2.position = new BABYLON.Vector3(3.75, 0.3, -2);    
    var myMaterialB = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialB.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M29.jpg", scene); 
    myBox2.material = myMaterialB;//mesh是之前创建的物体

    var myBox3 = myBox2.clone("myBox3");
    myBox3.position = new BABYLON.Vector3(3.75, 0.3, 0);  
    var myBox4 = myBox2.clone("myBox3");
    myBox4.position = new BABYLON.Vector3(3.75, 0.3, 0.5);  
    var myBox5 = myBox2.clone("myBox3");
    myBox5.position = new BABYLON.Vector3(3.75, 0.3, 2);  

    var myBox6 = myBox2.clone("myBox3");
    myBox6.position = new BABYLON.Vector3(-3.75, 0.3, -2);  
    var myBox7 = myBox2.clone("myBox3");
    myBox7.position = new BABYLON.Vector3(-3.75, 0.3, 0);  
    var myBox8 = myBox2.clone("myBox3");
    myBox8.position = new BABYLON.Vector3(-3.75, 0.3, 0.5);  
    var myBox9 = myBox2.clone("myBox3");
    myBox9.position = new BABYLON.Vector3(-3.75, 0.3, 2);  

    
    //3、冷凝器支架（小） 
    var myBox10 = BABYLON.MeshBuilder.CreateBox("myBox2", {height: 0.7+2.1, width: 0.05, depth: 1.9-0.02}, scene);
    myBox10.position = new BABYLON.Vector3(0, 1.25, 0);  
    var myCylinder = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 2, diameterTop: 1.12, diameterBottom:1.12, tessellation:32}, scene);
    myCylinder.rotation.z = Math.PI / 2;
    myCylinder.position = new BABYLON.Vector3(0, 0.3, 0);  

    var outerCSG = BABYLON.CSG.FromMesh(myBox10);
    var innerCSG = BABYLON.CSG.FromMesh(myCylinder);
    var pipeCSG = outerCSG.subtract(innerCSG);
    const myBox11 = pipeCSG.toMesh("mPipe", null, scene);
    myBox10.dispose();
    myCylinder.dispose();
    scene.removeMesh(myBox10);
    scene.removeMesh(myCylinder);
    myBox11.position = new BABYLON.Vector3(3.75, 1.25, 1.25);   
    var myMaterialC = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialC.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M29.jpg", scene); 
    myBox11.material = myMaterialC;//mesh是之前创建的物体

    var myBox12 = myBox11.clone("myBox12");
    myBox12.position = new BABYLON.Vector3(-3.75, 1.25, 1.25);   


    
    //4、蒸发器支架（大） 
    var myBox13 = BABYLON.MeshBuilder.CreateBox("myBox2", {height: 0.7+2.9, width: 0.05, depth: 2.4-0.02}, scene);
    myBox13.position = new BABYLON.Vector3(0, 1.5, 0);  
    myCylinder = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 2, diameterTop: 1.78, diameterBottom:1.78, tessellation:32}, scene);
    myCylinder.rotation.z = Math.PI / 2;
    myCylinder.position = new BABYLON.Vector3(0, 0.3, 0);  

    outerCSG = BABYLON.CSG.FromMesh(myBox13);
    innerCSG = BABYLON.CSG.FromMesh(myCylinder);
    pipeCSG = outerCSG.subtract(innerCSG);
    const myBox14 = pipeCSG.toMesh("mPipe", null, scene);
    myBox13.dispose();
    myCylinder.dispose();
    scene.removeMesh(myBox13);
    scene.removeMesh(myCylinder);
    myBox14.position = new BABYLON.Vector3(3.75, 1.25, -1);   
    var myMaterialD = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialD.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M29.jpg", scene); 
    myBox14.material = myMaterialC;//mesh是之前创建的物体

    var myBox15 = myBox14.clone("myBox12");
    myBox15.position = new BABYLON.Vector3(-3.75, 1.25, -1);   

    // 5、冷凝器圆桶
    var myCylinder1 = BABYLON.MeshBuilder.CreateCylinder("myCylinder1", {height: 7.5, diameterTop: 1.5, diameterBottom:1.5, tessellation:64}, scene);
    var myCylinder2 = BABYLON.MeshBuilder.CreateCylinder("myCylinder2", {height: 0.12, diameterTop: 1.7, diameterBottom:1.7, tessellation:64}, scene);
    myCylinder2.position = new BABYLON.Vector3(0, 3.81, 0);  
    var myCylinder3 = myCylinder2.clone("myCylinder3");
    myCylinder3.position = new BABYLON.Vector3(0, -3.81, 0); 
    var mySphere1 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:2, slice:0.3}, scene);
    mySphere1.position = new BABYLON.Vector3(0, 3.25, 0);  
    var mySphere2 = mySphere1.clone("mySphere2");
    mySphere2.rotation.x = Math.PI;
    mySphere2.position = new BABYLON.Vector3(0, -3.25, 0);  

    var myMaterialE = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialE.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M14.jpg", scene); 
    var myMaterialF = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialF.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M143.jpg", scene); 
    myCylinder1.material = myMaterialE; 
    myCylinder2.material = myMaterialF; 
    myCylinder3.material = myMaterialF; 
    mySphere1.material = myMaterialE; 
    mySphere2.material = myMaterialE; 

    var myBox116 = BABYLON.Mesh.MergeMeshes([myCylinder1,myCylinder2,myCylinder3,mySphere1,mySphere2], true, true, undefined, false, true);
    myBox116.name = "MyWaterChiller";

    myBox116.rotation.z = Math.PI/2;
    myBox116.position = new BABYLON.Vector3(0, 1.75, 1.25);  
    
    
    // 6、蒸发器圆桶
    var myCylinder4 = BABYLON.MeshBuilder.CreateCylinder("myCylinder1", {height: 7.5, diameterTop: 1.8, diameterBottom:1.8, tessellation:64}, scene);
    var myCylinder5 = BABYLON.MeshBuilder.CreateCylinder("myCylinder2", {height: 0.12, diameterTop: 2.0, diameterBottom:2.0, tessellation:64}, scene);
    myCylinder5.position = new BABYLON.Vector3(0, 3.81, 0);  
    var myCylinder6 = myCylinder5.clone("myCylinder3");
    myCylinder6.position = new BABYLON.Vector3(0, -3.81, 0); 
    var mySphere3 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:2.3, slice:0.33}, scene);
    mySphere3.position = new BABYLON.Vector3(0, 3.20, 0);  
    var mySphere4 = mySphere3.clone("mySphere2");
    mySphere4.rotation.x = Math.PI;
    mySphere4.position = new BABYLON.Vector3(0, -3.20, 0);  

    var myMaterialG = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialG.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M02.jpg", scene); 
    var myMaterialH = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialH.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M01.jpg", scene); 
    myCylinder4.material = myMaterialG; 
    myCylinder5.material = myMaterialH; 
    myCylinder6.material = myMaterialH; 
    mySphere3.material = myMaterialG; 
    mySphere4.material = myMaterialG; 
    
    var myBox117 = BABYLON.Mesh.MergeMeshes([myCylinder4,myCylinder5,myCylinder6,mySphere3,mySphere4], true, true, undefined, false, true);
    myBox117.name = "MyWaterChiller";

    myBox117.rotation.z = Math.PI/2;
    myBox117.position = new BABYLON.Vector3(0, 2, -1);  


    // 7、冷凝器控制箱
    var myBox18 = BABYLON.MeshBuilder.CreateBox("myBox2", {height: 1.7, width: 2, depth: 0.6}, scene);
    var myMaterialI = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialI.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M39.jpg", scene); 
    myBox18.material = myMaterialI; 
    myBox18.position = new BABYLON.Vector3(2, 3.5, 1.7); 

    var myBox19 = BABYLON.MeshBuilder.CreateBox("myBox2", {height: 0.8, width: 0.05, depth: 0.6}, scene);
    var myCylinder7 = BABYLON.MeshBuilder.CreateCylinder("myCylinder1", {height: 1, diameterTop: 1.2, diameterBottom:1.2, tessellation:64}, scene);
    myCylinder7.rotation.z = Math.PI/2;
    myCylinder7.position = new BABYLON.Vector3(0, 0, 0.7); 
    outerCSG = BABYLON.CSG.FromMesh(myBox19);
    innerCSG = BABYLON.CSG.FromMesh(myCylinder7);
    pipeCSG = outerCSG.subtract(innerCSG);
    const myBox20 = pipeCSG.toMesh("mPipe", null, scene);
    var myMaterialJ = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialJ.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M40.jpg", scene); 
    myBox20.material = myMaterialJ; 
    myBox19.dispose();
    myCylinder7.dispose();
    scene.removeMesh(myBox19);
    scene.removeMesh(myCylinder7);

    myBox20.position = new BABYLON.Vector3(2.975, 2.25, 1.7); 
    var myBox21 = myBox20.clone("myBox21");
    myBox21.position = new BABYLON.Vector3(1.025, 2.25, 1.7); 


    // 8、蒸发器控制箱
    var myBox22 = BABYLON.MeshBuilder.CreateBox("myBox2", {height: 1, width: 1, depth: 0.3}, scene);
    var myMaterialK = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialK.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M41.jpg", scene); 
    myBox22.material = myMaterialK; 
    myBox22.position = new BABYLON.Vector3(-2.5, 3.3, -1.8); 

    var myCylinder8 = BABYLON.MeshBuilder.CreateCylinder("myCylinder1", {height: 0.3, diameterTop: 1.2, diameterBottom:1.2, tessellation:64}, scene);
    var myCylinder9 = BABYLON.MeshBuilder.CreateCylinder("myCylinder1", {height: 1, diameterTop: 1.5, diameterBottom:1.5, tessellation:64}, scene);
    myCylinder9.position = new BABYLON.Vector3(0, 0, 0.3); 
    outerCSG = BABYLON.CSG.FromMesh(myCylinder8);
    innerCSG = BABYLON.CSG.FromMesh(myCylinder9);
    pipeCSG = outerCSG.subtract(innerCSG);
    const myBox23 = pipeCSG.toMesh("mPipe", null, scene);
    myCylinder8.dispose();
    myCylinder9.dispose();
    scene.removeMesh(myCylinder8);
    scene.removeMesh(myCylinder9);
    myBox23.rotation.z = Math.PI/2;
    myBox23.rotation.x = -Math.PI/4;
    myBox23.position = new BABYLON.Vector3(-2.5, 2.7, -1.3);  
    var myMaterialL = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialL.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M27.jpg", scene); 
    myBox23.material = myMaterialL; 

    // 9、冷凝器上方圆筒
    var myCylinder10 = BABYLON.MeshBuilder.CreateCylinder("myCylinder1", {height: 1.8, diameterTop: 1, diameterBottom:1, tessellation:64}, scene);
    var mySphere5 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:1, diameterY:0.4, diameterZ:1}, scene);
    mySphere5.position = new BABYLON.Vector3(0, 0.9, 0);
    var mySphere6 = mySphere5.clone("mySphere6");
    mySphere6.position = new BABYLON.Vector3(0, -0.9, 0);
    var myBox24 = BABYLON.MeshBuilder.CreateBox("myBox24", {height: 0.02, width: 1, depth: 0.3}, scene);
    var myBox25 = myBox24.clone("myBox25");
    myBox24.position = new BABYLON.Vector3(-0.5, 0.6, 0);
    myBox25.position = new BABYLON.Vector3(-0.5, -0.6, 0);

    var myBox26 = BABYLON.Mesh.MergeMeshes([myCylinder10,mySphere5,mySphere6,myBox24,myBox25], true, true, undefined, false, true);

    myBox26.rotation.z = Math.PI/2;
    myBox26.position = new BABYLON.Vector3(-2, 3.25, 1.25);
    var myMaterialM = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialM.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M143.jpg", scene); 
    myBox26.material = myMaterialM; 

    // 10、蒸发器上方的圆管组合
    var myCylinder11 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.36, diameterBottom:0.36, tessellation:64}, scene);
    myCylinder11.position = new BABYLON.Vector3(0, 5, 0);
    var myCylinder12 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.2, diameterTop: 0.36, diameterBottom:0.84, tessellation:64}, scene);
    myCylinder12.position = new BABYLON.Vector3(0, 5-0.15, 0); 
    var myCylinder13 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.4, diameterTop: 0.84, diameterBottom:0.84, tessellation:64}, scene);
    myCylinder13.position = new BABYLON.Vector3(0, 4.85-0.3, 0); 
    var myCylinder14 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.2, diameterTop: 0.84, diameterBottom:0.6, tessellation:64}, scene);
    myCylinder14.position = new BABYLON.Vector3(0, 4.55-0.3, 0); 
    var myCylinder15 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.3, diameterTop: 0.6, diameterBottom:0.6, tessellation:64}, scene);
    myCylinder15.position = new BABYLON.Vector3(0, 4.25-0.25, 0); 
    var myCylinder16 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.84, diameterBottom:0.84, tessellation:64}, scene);
    myCylinder16.position = new BABYLON.Vector3(0, 4.0-0.2, 0); 
    var myCylinder17 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.2, diameterTop: 0.84, diameterBottom:1.2, tessellation:64}, scene);
    myCylinder17.position = new BABYLON.Vector3(0, 3.8-0.15, 0); 
    var myCylinder18 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.5, diameterTop: 1.2, diameterBottom:1.2, tessellation:64}, scene);
    myCylinder18.position = new BABYLON.Vector3(0, 3.65-0.35, 0); 
    var myCylinder19 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.2, diameterTop: 1.08, diameterBottom:1.08, tessellation:64}, scene);
    myCylinder19.position = new BABYLON.Vector3(0, 3.3-0.35, 0); 
    var myCylinder20 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.96, diameterBottom:0.96, tessellation:64}, scene);
    myCylinder20.position = new BABYLON.Vector3(0, 2.95-0.15, 0); 
    var myCylinder21 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.3, diameterTop: 0.84, diameterBottom:0.6, tessellation:64}, scene);
    myCylinder21.position = new BABYLON.Vector3(0, 2.8-0.2, 0); 
    var myCylinder22 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.2, diameterTop: 0.6, diameterBottom:0.6, tessellation:64}, scene);
    myCylinder22.position = new BABYLON.Vector3(0, 2.6-0.25, 0); 

    var myBox28 = BABYLON.MeshBuilder.CreateBox("myBox24", {height: 0.02, width: 1.5, depth: 0.3}, scene);
    var myBox29 = myBox28.clone("myBox25");
    myBox28.position = new BABYLON.Vector3(0.5, 4.85-0.3, 0);
    myBox29.position = new BABYLON.Vector3(0.5, 3.65-0.35, 0);

    var myBox27 = BABYLON.Mesh.MergeMeshes([myCylinder11,myCylinder12,myCylinder13,myCylinder14,myCylinder15,myCylinder16,myCylinder17,myCylinder18,myCylinder19,myCylinder20,myCylinder21,myCylinder22,myBox28,myBox29], true, true, undefined, false, true);
    myBox27.rotation.z = -Math.PI/2;
    var myMaterialN = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialN.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M02.jpg", scene); 
    myBox27.material = myMaterialN; 
    myBox27.position = new BABYLON.Vector3(-3.2, 4, -1);

    // 11、蒸发器上第一个弯管
    var path=[]; 
    path.push(new BABYLON.Vector3(-0.8,4,-1));  
    var radius = 0.25; 
    var r = 1;  
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var da = Math.PI/2/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r * Math.cos(Math.PI/2 - da*i);
        let y = sy + r * Math.sin(Math.PI/2 -da*i) - r;
        path.push(new BABYLON.Vector3(x,y,-1));
    } 
    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    path.push(new BABYLON.Vector3(sx, sy-0.5, -1)); 
    var myPipe1=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    // 圆圈
    var myCylinder23 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.15, diameterTop: 0.7, diameterBottom:0.7, tessellation:64}, scene);
    myCylinder23.position = new BABYLON.Vector3(sx, sy+0.05, -1); 


    //mymesh.position = new BABYLON.Vector3(-4, 6, -6);
    var myMaterialO = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialO.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M01.jpg", scene);
    myMaterialO.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterialO.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myPipe1.material = myMaterialO;//mesh是之前创建的物体
    myCylinder23.material = myMaterialO;//mesh是之前创建的物体


    // 12、蒸发器上第二个弯管（链接冷凝器）
    path=[]; 
    path.push(new BABYLON.Vector3(0.1,4,-0.5));  
    path.push(new BABYLON.Vector3(0.1,3.7,0.2));  
    radius = 0.2; 
    r = 1;  
    // 第一个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    var sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx  ;
        let y = sy - r + r * Math.cos( Math.PI/4+ da*i);
        let z = sz + r * Math.sin( Math.PI/4+ da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  


    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z;
    path.push(new BABYLON.Vector3(sx, sy-0.5, sz)); 
    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    // 圆圈 下
    var myCylinder24 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.15, diameterTop: 0.6, diameterBottom:0.6, tessellation:64}, scene);
    myCylinder24.position = new BABYLON.Vector3(sx, sy, sz); 
    // 圆圈 上
    sx = path[1].x;
    sy = path[1].y; 
    sz = path[1].z;
    var myCylinder25 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.15, diameterTop: 0.6, diameterBottom:0.6, tessellation:64}, scene);
    myCylinder25.position = new BABYLON.Vector3(sx, sy, sz); 
    myCylinder25.rotation.x = -Math.PI*7.3/20;

    //mymesh.position = new BABYLON.Vector3(-4, 6, -6);
    var myMaterialP = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialP.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M01.jpg", scene);
    myMaterialP.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterialP.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myPipe2.material = myMaterialP;//mesh是之前创建的物体
    myCylinder24.material = myMaterialP;//mesh是之前创建的物体
    myCylinder25.material = myMaterialP;//mesh是之前创建的物体


    // 13、冷凝器 水管
    var myCylinder26 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 1.5, diameterTop: 0.5, diameterBottom:0.5, tessellation:64}, scene);
    var myCylinder27 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.7, diameterBottom:0.7, tessellation:64}, scene);
    myCylinder27.position = new BABYLON.Vector3(0, 0.8, 0); 
    myCylinder26.material = myMaterialM;
    myCylinder27.material = myMaterialM;
    var myBox30 = BABYLON.Mesh.MergeMeshes([myCylinder26,myCylinder27], true, true, undefined, true, true);
    myBox30.rotation.z = -Math.PI/2;
    var myBox31 = myBox30.clone("");
    myBox30.position = new BABYLON.Vector3(4, 2.2, 1.25); 
    myBox31.position = new BABYLON.Vector3(4, 1.3, 1.25); 

    // 颜色块
    var myCylinderA = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.7, diameterBottom:0.7, tessellation:64}, scene);
    var myCylinderB = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.6,diameterY:0.4,diameterZ:0.6}, scene);
    var myCylinderC = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.7, diameterBottom:0.7, tessellation:64}, scene);
    myCylinderA.position = new BABYLON.Vector3(0, 0.9, 0); 
    myCylinderB.position = new BABYLON.Vector3(0, 1.05, 0); 
    myCylinderC.position = new BABYLON.Vector3(0, 1.2, 0); 
    var myBoxA = BABYLON.Mesh.MergeMeshes([myCylinderA,myCylinderB,myCylinderC], true, true, undefined, true, true);
    myBoxA.rotation.z = -Math.PI/2;
    myBoxA.position = new BABYLON.Vector3(4, 2.2, 1.25); 
    var myMaterialQ = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialQ.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    myBoxA.material = myMaterialQ;//mesh是之前创建的物体

    var myBoxB = myBoxA.clone("");
    myBoxB.position = new BABYLON.Vector3(4, 1.3, 1.25); 
    var myMaterialR = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialR.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P4.jpg", scene);
    myBoxB.material = myMaterialR;//mesh是之前创建的物体

    // 14、蒸发器 水管
    var myCylinder28 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 1.5, diameterTop: 0.5, diameterBottom:0.5, tessellation:64}, scene);
    var myCylinder29 = BABYLON.MeshBuilder.CreateCylinder("myCylinder11", {height: 0.1, diameterTop: 0.7, diameterBottom:0.7, tessellation:64}, scene);
    myCylinder29.position = new BABYLON.Vector3(0, 0.8, 0); 
    myCylinder28.material = myMaterialO;
    myCylinder29.material = myMaterialO;
    var myBox32 = BABYLON.Mesh.MergeMeshes([myCylinder28,myCylinder29], true, true, undefined, true, true);
    myBox32.rotation.z = -Math.PI/2;
    var myBox33 = myBox32.clone("");
    myBox32.position = new BABYLON.Vector3(4, 2.5, -1); 
    myBox33.position = new BABYLON.Vector3(4, 1.6, -1); 

    // 颜色块
    var myBoxC = myBoxA.clone("");
    myBoxC.position = new BABYLON.Vector3(4, 2.5, -1); 
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P1.jpg", scene);
    myBoxC.material = myMaterialS;//mesh是之前创建的物体

    var myBoxD = myBoxA.clone("");
    myBoxD.position = new BABYLON.Vector3(4, 1.6, -1); 
    var myMaterialT = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialT.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P2.jpg", scene);
    myBoxD.material = myMaterialT;//mesh是之前创建的物体


    // var AllMesh = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3,myBox4,myBox5,myBox6,myBox7,myBox8,myBox9,myBox11,myBox12,myBox14,myBox15,myBox116,
    //     myBox117,myBox18,myBox20,myBox21,myBox22,myBox23,myBox26,myBox27,myPipe1,myCylinder23,myPipe2,myCylinder24,myCylinder25], true, true, undefined, false, true);

    var w1 = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3,myBox4,myBox5,myBox6,myBox7,myBox8,myBox9,myBox11,myBox12,myBox14,myBox15,myBox116,      myBox117,myBox18,myBox20,myBox21,myBox22,myBox23,myBox26,myBox27,myPipe1,myCylinder23,myPipe2,myCylinder24,myCylinder25,myBox30,myBox31,myBox32,myBox33,myBoxA,myBoxB,myBoxC,myBoxD], true, true, undefined, true, true);
    var w2 = w1.clone();
    var w3 = w1.clone();
    w1.position =  new BABYLON.Vector3(11.25, 0, 26.5);  
    w2.position =  new BABYLON.Vector3(11.25, 0, 19);  
    w3.position =  new BABYLON.Vector3(11.25, 0, 11.5);  
    return w1;
}

// 蒸发器管道 上
function createZhengFaUp(scene)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P1.jpg", scene);
    myMaterialS.alpha = 0.5;
    var path=[]; 
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 0.5,2.5,26.5-1));  
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1,2.5,26.5-1));   
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5,2.5,26.5-1));  
    var radius = 0.25; 
    var r = 2;  
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx  + r * Math.sin( da*i);
        let y = sy + r - r * Math.cos(da*i);
        let z = sz ;
        path.push(new BABYLON.Vector3(x,y,z));
    }  


    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx+12-sy, 12, sz)); 
    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;

    var myPipe3 = myPipe2.clone("");
    var myPipe4 = myPipe2.clone("");
    myPipe3.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);
    myPipe4.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);

    
    // 水流1
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 7.5;
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 7.5;
    createWaterLoop1(scene, path, 0.2, 0.7);

    // 复原
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z + 15;
    return path; 
}

// 蒸发器管道 下
function createZhengFaDown(scene)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P2.jpg", scene);
    myMaterialS.alpha = 0.5;
    var path=[]; 
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 0.5,1.6,26.5-1));  
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5,1.6,26.5-1)); 
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+0.5,1.6,26.5-1));  
    var radius = 0.25; 
    var r = 2;  
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx  + r * Math.sin( da*i);
        let y = sy + r - r * Math.cos(da*i);
        let z = sz ;
        path.push(new BABYLON.Vector3(x,y,z));
    }  


    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z;
    path.push(new BABYLON.Vector3(sx+12-sy, 12, sz)); 
    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;

    
    var myPipe3 = myPipe2.clone("");
    var myPipe4 = myPipe2.clone("");
    myPipe3.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);
    myPipe4.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);
    
    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 7.5;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 7.5;
    createWaterLoop1(scene, path2, 0.2, 0.7);

    // // 复原
    // for (let i = 0; i < path.length; i++)
    //     path[i].z = path[i].z + 15;
    return path; 
}


// 冷凝器管道 上
function createLengNingUp(scene)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    myMaterialS.alpha = 0.5;
    var path=[]; 
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 0.5,2.2,26.5+1.25));  
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5,2.2,26.5+1.25));   
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 2.5,2.2,26.5+1.25)); 
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+2.5,2.2,26.5+1.25));  
    var radius = 0.25; 
    var r = 2;  
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx  + r * Math.sin( da*i);
        let y = sy + r - r * Math.cos(da*i);
        let z = sz ;
        path.push(new BABYLON.Vector3(x,y,z));
    }  


    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z;
    path.push(new BABYLON.Vector3(sx+12-sy, 12, sz)); 
    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;


    var myPipe3 = myPipe2.clone("");
    var myPipe4 = myPipe2.clone("");
    myPipe3.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);
    myPipe4.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);

    
    // 水流1
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 7.5;
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 7.5;
    createWaterLoop1(scene, path, 0.2, 0.7);

    // 复原
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z + 15;
    return path; 
}


// 冷凝器管道 下
function createLengNingDown(scene)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P4.jpg", scene);
    myMaterialS.alpha = 0.5;
    var path=[]; 
    // path.push(new BABYLON.Vector3(11.25 + 9/2 + 0.5,   1.3,26.5+1.25));  
    // path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5,   1.3,26.5+1.25));  
    // path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+1, 1.3,26.5+1.25));  
    // path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+2, 1.3,26.5+1.25));  
    // path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+2.999,  1.3,26.5+1.25));  
    // path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+3,      1.3,26.5+1.25));  
    // var radius = 0.25; 
    // var r = 2;  
    // // 第一个弧度
    // var sx = path[path.length-1].x;
    // var sy = path[path.length-1].y;
    // var sz = path[path.length-1].z;
    // var da = 45*Math.PI/2/90/20;
    // for (let i = 0; i< 20; i++) 
    // { 
    //     let x = sx  + r * Math.sin( da*i);
    //     let y = sy + r - r * Math.cos(da*i);
    //     let z = sz ;
    //     path.push(new BABYLON.Vector3(x,y,z));
    // }  
     
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 0.5,1.3,26.5+1.25));  
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5,1.3,26.5+1.25));   
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 2.5,1.3,26.5+1.25)); 
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+2.5,1.3,26.5+1.25));  
    path.push(new BABYLON.Vector3(11.25 + 9/2 + 1.5+3,1.3,26.5+1.25));  
    var radius = 0.25; 
    var r = 2;  
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx  + r * Math.sin( da*i);
        let y = sy + Math.round((r - r * Math.cos(da*i))*1000)/1000;
        let z = sz ;
        path.push(new BABYLON.Vector3(x,y,z));
    }  


    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z;
    path.push(new BABYLON.Vector3(sx+12-sy, 12, sz)); 
    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;

    console.log(path);
    
    var myPipe3 = myPipe2.clone("");
    var myPipe4 = myPipe2.clone("");
    myPipe3.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);
    myPipe4.position = new BABYLON.Vector3(myPipe3.position.x,myPipe3.position.y,myPipe3.position.z - 7.5);

    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 7.5;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 7.5;
    createWaterLoop1(scene, path2, 0.2, 0.7);

    // // 复原
    // for (let i = 0; i < path.length; i++)
    //     path[i].z = path[i].z + 15;
    return path; 
}

function createZhengFaPipeUp(scene, pa)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P1.jpg", scene);
    myMaterialS.alpha=0.5;//半透明
    var sx = pa[pa.length -1].x;
    var sy = pa[pa.length -1].y;
    var sz = pa[pa.length -1].z;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz+4));   
    path.push(new BABYLON.Vector3(sx,sy,sz));     
    path.push(new BABYLON.Vector3(sx,sy,8));    
    var radius = 0.35; 
    var r = 1;  
    // 第一个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) // 原来是20，改为10
    { 
        let x = sx - r + r * Math.cos(da*i);
        let y = sy ;
        let z = sz  - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间小弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx-1, sy, sz-1)); 

    // 第二个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++)  // 原来是20，改为10
    { 
        let x = sx - Math.abs( r * Math.cos(45*Math.PI/2/90) -  r * Math.cos(45*Math.PI/2/90+da*i));
        let y = sy ;
        let z = sz  - Math.abs( r * Math.sin(45*Math.PI/2/90) - r * Math.sin(45*Math.PI/2/90 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(-18, sy, sz)); 
    
    
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r * Math.sin(da*i);
        let y = sy ;
        let z = sz  - (r - r * Math.cos( da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy, sz-10)); 

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;

    //myPipe2.material.alpha=0.5;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.x = -Math.PI/2;


    // 水流
    createWaterLoop1(scene, path, 0.3, 1);
    return myPipe2;
}

// 根据路径（点列表）、每一段的长度，切割成多个点
function computePath(path, len)
{
    var newpath = [];
    for(let i = 1; i < path.length; i++)
    {
        let x1 = path[i-1].x;        let y1 = path[i-1].y;        let z1 = path[i-1].z;
        let x2 = path[i].x;          let y2 = path[i].y;          let z2 = path[i].z;
        let totallen = Math.sqrt((x2-x1)*(x2-x1)+(y2-y1)*(y2-y1)+(z2-z1)*(z2-z1));
        let k = parseInt(totallen/len);
        newpath.push(new BABYLON.Vector3(x1, y1, z1));
        //console.log("长度："+k);
        for(let j = 1; j < k; j++)
        {
            newpath.push(new BABYLON.Vector3((x1+(x2-x1)*len*j/totallen), (y1+(y2-y1)*len*j/totallen), (z1+(z2-z1)*len*j/totallen)));
        }
    }
    //console.log("长度："+newpath.length);
    return newpath;
}

// 创建水流方向
function createWaterLoop1(scene, oldpath, myradius, myheight)
{
    // 路径重新插值
    var path = computePath(oldpath,0.05);

    const path3d = new BABYLON.Path3D(path);
    const curve = path3d.getCurve(); // create the curve
    const tangents = path3d.getTangents();  //array of tangents to the curve
    const normals = path3d.getNormals(); //array of normals to the curve
    const binormals = path3d.getBinormals(); //array of binormals to curve
    
    function orientation(i, p) {
        var pp = [];
        switch (i) {
            case 0:
                pp.push(new BABYLON.Vector3.RotationFromAxis(tangents[p], normals[p], binormals[p]));
                break;
            case 1:
                pp.push(new BABYLON.Vector3.RotationFromAxis(tangents[p], binormals[p], normals[p]));
                break;
            case 2:
                pp.push(new BABYLON.Vector3.RotationFromAxis(normals[p], tangents[p], binormals[p]));
                break;
            case 3:
                pp.push(new BABYLON.Vector3.RotationFromAxis(normals[p], binormals[p], tangents[p]));
                break;
            case 4:
                pp.push(new BABYLON.Vector3.RotationFromAxis(binormals[p], tangents[p], normals[p]));
                break;
            case 5:
                pp.push(new BABYLON.Vector3.RotationFromAxis(binormals[p], normals[p], tangents[p]));
                break;
        }
        return pp;
    }


    // const cone = BABYLON.MeshBuilder.CreateCylinder("cone", { diameterBottom: 0.3, diameterTop: 0, height: 1});
    // let p = 0;

    // scene.registerBeforeRender(function () {
    //     cone.rotation = orientation(4, p)[0];
    //     cone.position = path[p];
    //     p++;
    //     p %= curve.length;
    // });
    var meshlist = [];
    var cone = BABYLON.MeshBuilder.CreateCylinder("cone", { diameterBottom: myradius, diameterTop: 0, height: myheight});
    let count =  parseInt(path.length / 70);
    for (let i = 0; i<count; i++) 
    {
        var mesh = cone.clone("");        
        meshlist.push({mesh:mesh,p:i*70, t:0});
    } 
    scene.removeMesh(cone);
    scene.registerBeforeRender(function () {
        for (let i = 0; i<meshlist.length; i++) 
        {
            meshlist[i].mesh.rotation = orientation(4, meshlist[i].p)[0];
            meshlist[i].mesh.position = path[meshlist[i].p];
            // meshlist[i].t++;
            // if (meshlist[i].t % 5 == 0)    // 延时，每个点停留10次
            {
                meshlist[i].t = 0;
                meshlist[i].p++;
                meshlist[i].p %= curve.length;
            }
        }
    });
}

function createZhengFaPipeDown(scene, pa)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P2.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = pa[pa.length -1].x;
    var sy = pa[pa.length -1].y;
    var sz = pa[pa.length -1].z;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz+4));   
    path.push(new BABYLON.Vector3(sx,sy,sz));     
    path.push(new BABYLON.Vector3(sx,sy,7.5));    
    var radius = 0.35; 
    var r = 1;  
    // 第一个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) 
    { 
        let x = sx - r + r * Math.cos(da*i);
        let y = sy ;
        let z = sz  - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间小弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx-2, sy, sz-2)); 

    // 第二个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) 
    { 
        let x = sx - Math.abs( r * Math.cos(45*Math.PI/2/90) -  r * Math.cos(45*Math.PI/2/90+da*i));
        let y = sy ;
        let z = sz  - Math.abs( r * Math.sin(45*Math.PI/2/90) - r * Math.sin(45*Math.PI/2/90 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(-10, sy, sz)); 
    
    

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.x = -Math.PI/2;

    var mymesh4 = mymesh3.clone();
    mymesh4.position = new BABYLON.Vector3(path[path.length-1].x,path[path.length-1].y,path[path.length-1].z);
    mymesh4.rotation.z = -Math.PI/2;

    
    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.3, 1);
    return myPipe2;
}

function createLengNingPipeUp(scene, pa)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = pa[pa.length -1].x;
    var sy = pa[pa.length -1].y;
    var sz = pa[pa.length -1].z;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz+1.75));   
    path.push(new BABYLON.Vector3(sx,sy,sz));     
    path.push(new BABYLON.Vector3(sx,sy,5));    
    var radius = 0.35; 
    var r = 2;  

    // 下陷的弧度
    // 下转45
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx ;
        let y = sy - r + r * Math.cos( da*i);
        let z = sz - r * Math.sin(da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 回转45（回平）
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx ;
        let y = sy - r * Math.abs(Math.sin(Math.PI/4) - Math.sin(Math.PI/4 + da*i));
        let z = sz - r * Math.abs(Math.cos(Math.PI/4) - Math.cos(Math.PI/4 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  

    // 直管段
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    path.push(new BABYLON.Vector3(sx,sy,sz-2));

    // 上转45
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx ;
        let y = sy + r - r * Math.cos( da*i);
        let z = sz - r * Math.sin(da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 回转45（回平）
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx ;
        let y = sy + r * Math.abs(Math.sin(Math.PI/4) - Math.sin(Math.PI/4 + da*i));
        let z = sz - r * Math.abs(Math.cos(Math.PI/4) - Math.cos(Math.PI/4 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  

    // 直管段
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx,sy,sz-0.5));

    // 第一个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r + r * Math.cos(da*i);
        let y = sy ;
        let z = sz  - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间小弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx-1, sy, sz-1)); 

    // 第二个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - Math.abs( r * Math.cos(45*Math.PI/2/90) -  r * Math.cos(45*Math.PI/2/90+da*i));
        let y = sy ;
        let z = sz  - Math.abs( r * Math.sin(45*Math.PI/2/90) - r * Math.sin(45*Math.PI/2/90 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(-8.5, sy, sz)); 
    
    
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r * Math.sin(da*i);
        let y = sy ;
        let z = sz  - (r - r * Math.cos( da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy, sz-30.05)); 

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.x = -Math.PI/2;

    
    var mymesh4 = mymesh3.clone("");
    mymesh4.position = new BABYLON.Vector3(path[path.length-1].x,path[path.length-1].y,path[path.length-1].z);
    mymesh4.rotation.y = Math.PI;


     
    // 水流1
    createWaterLoop1(scene, path, 0.3, 1);

    return myPipe2;
}

function createLengNingPipeDown(scene, pa)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P4.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = pa[pa.length -1].x;
    var sy = pa[pa.length -1].y;
    var sz = pa[pa.length -1].z;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz+1.75));   
    path.push(new BABYLON.Vector3(sx,sy,sz));     
    path.push(new BABYLON.Vector3(sx,sy,4));    
    var radius = 0.35; 
    var r = 1;  
    // 第一个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) 
    { 
        let x = sx - r + r * Math.cos(da*i);
        let y = sy ;
        let z = sz  - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间小弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx-1, sy, sz-1)); 

    // 第二个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) 
    { 
        let x = sx - Math.abs( r * Math.cos(45*Math.PI/2/90) -  r * Math.cos(45*Math.PI/2/90+da*i));
        let y = sy ;
        let z = sz  - Math.abs( r * Math.sin(45*Math.PI/2/90) - r * Math.sin(45*Math.PI/2/90 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(10, sy, sz)); 
     

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    var mymesh4 = mymesh3.clone("");
    mymesh3.rotation.x = -Math.PI/2;

    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    mymesh4.position = new BABYLON.Vector3(sx,sy,sz);
    mymesh4.rotation.z = -Math.PI/2;

    
    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.3, 1);

    return myPipe2;
}

// 冷凝器第二段大管
function createLengNingPipeDown2(scene, pa)
{ 
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P4.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = pa[pa.length -1].x;
    var sy = pa[pa.length -1].y;
    var sz = -0.35;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz));   
    path.push(new BABYLON.Vector3(sx-5,sy,sz));     
    path.push(new BABYLON.Vector3(-11,sy,sz));    
    var radius = 0.36; 
    var r = 1;  
   
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r * Math.sin(da*i);
        let y = sy ;
        let z = sz  - (r - r * Math.cos( da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy, sz-36.5)); 

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.z = Math.PI/2;

    var mymesh4 = mymesh3.clone("");
    mymesh4.position = new BABYLON.Vector3(path[path.length-1].x,path[path.length-1].y,path[path.length-1].z);
    mymesh4.rotation.y = Math.PI/2;


    
    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.3, 1);

    return myPipe2;
}


// 蒸发器第二段大管
function createZhengFaPipeDown2(scene, pa)
{ 
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P2.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = 9;
    var sy = pa[pa.length -1].y;
    var sz = 2.05;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz));   
    path.push(new BABYLON.Vector3(sx-5,sy,sz));     
    path.push(new BABYLON.Vector3(-16.5,sy,sz));    
    var radius = 0.36; 
    var r = 1;  
   
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r * Math.sin(da*i);
        let y = sy ;
        let z = sz  - (r - r * Math.cos( da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy, sz-6)); 

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.z = Math.PI/2;


    
    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.3, 1);

    return myPipe2;
}




// 水泵(绿色管子)
function  createWaterPump1(scene)
{
    //1、底座
    var myBox1 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.3, width: 1.5, depth: 5}, scene);
    myBox1.position = new BABYLON.Vector3(0, 0.15, 0);
    
    var myMaterialA = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialA.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G3.jpg", scene); 
    myMaterialA.diffuseTexture.uScale = 2.0;//垂直方向重复5次
    myMaterialA.diffuseTexture.vScale = 3.0;//水平方向重复5次
    myBox1.material = myMaterialA;//mesh是之前创建的物体

    //2、  圆环
    var mymesh1 =  BABYLON.MeshBuilder.CreateTorus("torus222", {thickness: 0.05,diameter:0.15,tessellation:50}, scene);    
    var myMaterialB = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialB.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    mymesh1.material = myMaterialB;
    var mymesh2 = mymesh1.clone("");
    var mymesh3 = mymesh1.clone("");
    mymesh1.position = new BABYLON.Vector3(0, 0.31, 0);
    mymesh2.position = new BABYLON.Vector3(0, 0.36, 0);
    mymesh3.position = new BABYLON.Vector3(0, 0.41, 0);
    mymesh1.rotation.z = 5*Math.PI/2/90;
    mymesh2.rotation.z = -5*Math.PI/2/90;
    mymesh3.rotation.z = 5*Math.PI/2/90;
    var myBox2 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2,mymesh3], true, true, undefined, false, true);
    myBox2.position = new BABYLON.Vector3(-0.65,myBox2.position.y, -2.4);
    var myBox3 = myBox2.clone("");
    var myBox4 = myBox2.clone("");
    var myBox5 = myBox2.clone("");
    var myBox6 = myBox2.clone("");
    var myBox7 = myBox2.clone("");
    myBox3.position = new BABYLON.Vector3(0.65,myBox2.position.y, 2.4);
    myBox4.position = new BABYLON.Vector3(0.65,myBox2.position.y, -2.4);
    myBox5.position = new BABYLON.Vector3(-0.65,myBox2.position.y, 2.4);
    myBox6.position = new BABYLON.Vector3(-0.65,myBox2.position.y, 0);
    myBox7.position = new BABYLON.Vector3(0.65,myBox2.position.y, 0);

    
    //3、底座 上
    var myBox8 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.2, width: 1.5, depth: 5}, scene);
    myBox8.position = new BABYLON.Vector3(0, 0.53, 0);
    
    var myMaterialC = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialC.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G1.jpg", scene); 
    myMaterialC.diffuseTexture.uScale = 2.0;//垂直方向重复5次
    myMaterialC.diffuseTexture.vScale = 3.0;//水平方向重复5次
    myBox8.material = myMaterialC;//mesh是之前创建的物体

    //4、水泵
    var myMaterialE = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialE.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P4.jpg", scene);
    var myMaterialD = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialD.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P5.jpg", scene);
    var mymesh420 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:1.0,diameterY:0.3,diameterZ:1.0}, scene);
    mymesh420.position = new BABYLON.Vector3(0, 0.73, 0);
    mymesh420.material = myMaterialD;
    var mymesh4 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 1.3, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh4.position = new BABYLON.Vector3(0, 1.38, 0);
    mymesh4.material = myMaterialD;
    var mymesh5 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.1, diameterBottom:1.1}, scene);
    mymesh5.position = new BABYLON.Vector3(0, 2.03, 0);
    mymesh5.material = myMaterialD;
    var mymesh6 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.2, diameterBottom:1.2}, scene);
    mymesh6.position = new BABYLON.Vector3(0, 2.23, 0);
    mymesh6.material = myMaterialD;
    var mymesh7 =  BABYLON.MeshBuilder.CreateTorus("torus222", {thickness: 0.3,diameter:1.1,tessellation:50}, scene);
    mymesh7.position = new BABYLON.Vector3(0, 2.48, 0);
    mymesh7.material = myMaterialD;
    var mymesh8 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.3, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh8.position = new BABYLON.Vector3(0, 2.7, 0);
    mymesh8.material = myMaterialD;
    var mymesh9 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh9.position = new BABYLON.Vector3(0, 2.9, 0);
    mymesh9.material = myMaterialD;
    var mymesh10 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.6, diameterTop: 0.8, diameterBottom:0.6}, scene);
    mymesh10.position = new BABYLON.Vector3(0, 3.25, 0);
    mymesh10.material = myMaterialD;

    var mymesh11 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh11.position = new BABYLON.Vector3(0, 3.65, 0);
    mymesh11.material = myMaterialD;
    var mymesh12 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.9,diameterY:0.6,diameterZ:0.9}, scene);
    mymesh12.position = new BABYLON.Vector3(0, 3.9, 0);
    mymesh12.material = myMaterialD;
    var mymesh13 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh13.position = new BABYLON.Vector3(0, 4.15, 0);
    mymesh13.material = myMaterialD;
    var mymesh14 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.3, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh14.position = new BABYLON.Vector3(0, 4.4, 0);
    mymesh14.material = myMaterialD;
    var mymesh15 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 1.0, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh15.position = new BABYLON.Vector3(0, 5.05, 0);
    mymesh15.material = myMaterialD;
    var mymesh16 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 1.2, diameterBottom:1.2}, scene);
    mymesh16.position = new BABYLON.Vector3(0, 5.6, 0);
    mymesh16.material = myMaterialD;
    var mymesh17 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 1.2, diameterBottom:1.2}, scene);
    mymesh17.position = new BABYLON.Vector3(0, 5.75, 0);
    mymesh17.material = myMaterialD;

    var mymesh18 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.2, width: 1.0, depth: 1}, scene);
    mymesh18.position = new BABYLON.Vector3(0, 1.38, -0.5);
    mymesh18.material = myMaterialE;
    var mymesh19 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.2, width: 1.0, depth: 1}, scene);
    mymesh19.position = new BABYLON.Vector3(0, 5.05, -0.5);
    mymesh19.material = myMaterialE;

    var myBox9 = BABYLON.Mesh.MergeMeshes([mymesh420,mymesh4,mymesh5,mymesh6,mymesh7,mymesh8,mymesh9,mymesh10,mymesh11,mymesh12,mymesh13,mymesh14,mymesh15,mymesh16,mymesh17,mymesh18,mymesh19], true, true, undefined, false, true);
    myBox9.rotation.x = -Math.PI/2;
    myBox9.position = new BABYLON.Vector3(0, 1.45, 3.1);

    // 5、两根长柱（小）
    var mymesh20 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 10.5, diameterTop: 0.3, diameterBottom:0.3}, scene);
    mymesh20.position = new BABYLON.Vector3(0, 6.8, 0.6);
    mymesh20.material = myMaterialD;
    var mymesh21 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.5, diameterBottom:0.5}, scene);
    mymesh21.position = new BABYLON.Vector3(0, 2.5, 0.6);
    mymesh21.material = myMaterialD;
    var mymesh22 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.5, diameterTop: 0.5, diameterBottom:0.3}, scene);
    mymesh22.position = new BABYLON.Vector3(0, 2.8, 0.6);
    mymesh22.material = myMaterialD;
    var mymesh23 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.7, diameterBottom:0.7}, scene);
    mymesh23.position = new BABYLON.Vector3(0, 3.1, 0.6);
    mymesh23.material = myMaterialD;
    var mymesh24 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.6,diameterY:0.5,diameterZ:0.6}, scene);
    mymesh24.position = new BABYLON.Vector3(0, 3.3, 0.6);
    mymesh24.material = myMaterialD;
    var mymesh25 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh25.position = new BABYLON.Vector3(0, 3.5, 0.6);
    mymesh25.material = myMaterialD;
    var mymesh26 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 1, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh26.position = new BABYLON.Vector3(0, 4.05, 0.6);
    mymesh26.material = myMaterialD;
    var mymesh27 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh27.position = new BABYLON.Vector3(0, 4.6, 0.6);
    mymesh27.material = myMaterialD;
    var mymesh28 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 7.5, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh28.position = new BABYLON.Vector3(0, 8.2, 0.6);
    mymesh28.material = myMaterialD; 
    var mymesh29 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh29.position = new BABYLON.Vector3(0, 5.65, 0.6);
    mymesh29.material = myMaterialD;
    var mymesh30 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh30.position = new BABYLON.Vector3(0, 7, 0.6);
    mymesh30.material = myMaterialD;
    var mymesh31 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh31.position = new BABYLON.Vector3(0, 8, 0.6);
    mymesh31.material = myMaterialD;
    var mymesh32 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh32.position = new BABYLON.Vector3(0, 9, 0.6);
    mymesh32.material = myMaterialD;
    var mymesh33 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.9, diameterBottom:0.9}, scene);
    mymesh33.position = new BABYLON.Vector3(0, 10.5, 0.6);
    mymesh33.material = myMaterialD;

    var myBox10 = BABYLON.Mesh.MergeMeshes([mymesh20,mymesh21,mymesh22,mymesh23,mymesh24,mymesh25,mymesh26,mymesh27,mymesh28,mymesh29,mymesh30,mymesh31,mymesh32,mymesh33], true, true, undefined, false, true);

    
    // 6、两根长柱（大）
    var mymesh40 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 10.5, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh40.position = new BABYLON.Vector3(0, 6.8, -1.8);
    mymesh40.material = myMaterialD;
    var mymesh41 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh41.position = new BABYLON.Vector3(0, 2.3, -1.8);
    mymesh41.material = myMaterialD;
    var mymesh42 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh42.position = new BABYLON.Vector3(0, 2.45, -1.8);
    mymesh42.material = myMaterialD;
    var mymesh43 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh43.position = new BABYLON.Vector3(0, 3.0, -1.8);
    mymesh43.material = myMaterialD;
    var mymesh44 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.7,diameterY:0.5,diameterZ:0.7}, scene);
    mymesh44.position = new BABYLON.Vector3(0, 3.2, -1.8);
    mymesh44.material = myMaterialD;
    var mymesh45 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh45.position = new BABYLON.Vector3(0, 3.4, -1.8);
    mymesh45.material = myMaterialD;
    var mymesh46 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh46.position = new BABYLON.Vector3(0, 5.0, -1.8);
    mymesh46.material = myMaterialD;
    var mymesh47 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh47.position = new BABYLON.Vector3(0, 8.0, -1.8);
    mymesh47.material = myMaterialD;
    var mymesh48 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh48.position = new BABYLON.Vector3(0, 10.0, -1.8);
    mymesh48.material = myMaterialD;


    var myBox11 = BABYLON.Mesh.MergeMeshes([mymesh40,mymesh41,mymesh42,mymesh43,mymesh44,mymesh45,mymesh46,mymesh47,mymesh48], true, true, undefined, false, true);

    // 返回总的
    var myPump = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3,myBox4,myBox5,myBox6,myBox7,myBox8,myBox9,myBox10,myBox11], true, true, undefined, false, true);
    myPump.position = new BABYLON.Vector3(21, 0, 1.43);

    var myPump2 = myPump.clone("");
    var myPump3 = myPump.clone("");
    var myPump4 = myPump.clone("");
    myPump2.position = new BABYLON.Vector3(18, 0, 1.43);
    myPump3.position = new BABYLON.Vector3(15, 0, 1.43);
    myPump4.position = new BABYLON.Vector3(12, 0, 1.43);

    return myPump;
}



// 水泵(红色管子)
function  createWaterPump2(scene)
{
    //1、底座
    var myBox1 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.3, width: 1.5, depth: 5}, scene);
    myBox1.position = new BABYLON.Vector3(0, 0.15, 0);
    
    var myMaterialA = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialA.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G3.jpg", scene); 
    myMaterialA.diffuseTexture.uScale = 2.0;//垂直方向重复5次
    myMaterialA.diffuseTexture.vScale = 3.0;//水平方向重复5次
    myBox1.material = myMaterialA;//mesh是之前创建的物体

    //2、  圆环
    var mymesh1 =  BABYLON.MeshBuilder.CreateTorus("torus222", {thickness: 0.05,diameter:0.15,tessellation:50}, scene);    
    var myMaterialB = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialB.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    mymesh1.material = myMaterialB;
    var mymesh2 = mymesh1.clone("");
    var mymesh3 = mymesh1.clone("");
    mymesh1.position = new BABYLON.Vector3(0, 0.31, 0);
    mymesh2.position = new BABYLON.Vector3(0, 0.36, 0);
    mymesh3.position = new BABYLON.Vector3(0, 0.41, 0);
    mymesh1.rotation.z = 5*Math.PI/2/90;
    mymesh2.rotation.z = -5*Math.PI/2/90;
    mymesh3.rotation.z = 5*Math.PI/2/90;
    var myBox2 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2,mymesh3], true, true, undefined, false, true);
    myBox2.position = new BABYLON.Vector3(-0.65,myBox2.position.y, -2.4);
    var myBox3 = myBox2.clone("");
    var myBox4 = myBox2.clone("");
    var myBox5 = myBox2.clone("");
    var myBox6 = myBox2.clone("");
    var myBox7 = myBox2.clone("");
    myBox3.position = new BABYLON.Vector3(0.65,myBox2.position.y, 2.4);
    myBox4.position = new BABYLON.Vector3(0.65,myBox2.position.y, -2.4);
    myBox5.position = new BABYLON.Vector3(-0.65,myBox2.position.y, 2.4);
    myBox6.position = new BABYLON.Vector3(-0.65,myBox2.position.y, 0);
    myBox7.position = new BABYLON.Vector3(0.65,myBox2.position.y, 0);

    
    //3、底座 上
    var myBox8 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.2, width: 1.5, depth: 5}, scene);
    myBox8.position = new BABYLON.Vector3(0, 0.53, 0);
    
    var myMaterialC = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialC.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/G1.jpg", scene); 
    myMaterialC.diffuseTexture.uScale = 2.0;//垂直方向重复5次
    myMaterialC.diffuseTexture.vScale = 3.0;//水平方向重复5次
    myBox8.material = myMaterialC;//mesh是之前创建的物体

    //4、水泵
    var myMaterialE = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialE.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P2.jpg", scene);
    var myMaterialD = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialD.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P2.jpg", scene);
    var mymesh420 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:1.0,diameterY:0.3,diameterZ:1.0}, scene);
    mymesh420.position = new BABYLON.Vector3(0, 0.73, 0);
    mymesh420.material = myMaterialD;
    var mymesh4 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 1.3, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh4.position = new BABYLON.Vector3(0, 1.38, 0);
    mymesh4.material = myMaterialD;
    var mymesh5 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.1, diameterBottom:1.1}, scene);
    mymesh5.position = new BABYLON.Vector3(0, 2.03, 0);
    mymesh5.material = myMaterialD;
    var mymesh6 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.2, diameterBottom:1.2}, scene);
    mymesh6.position = new BABYLON.Vector3(0, 2.23, 0);
    mymesh6.material = myMaterialD;
    var mymesh7 =  BABYLON.MeshBuilder.CreateTorus("torus222", {thickness: 0.3,diameter:1.1,tessellation:50}, scene);
    mymesh7.position = new BABYLON.Vector3(0, 2.48, 0);
    mymesh7.material = myMaterialD;
    var mymesh8 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.3, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh8.position = new BABYLON.Vector3(0, 2.7, 0);
    mymesh8.material = myMaterialD;
    var mymesh9 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh9.position = new BABYLON.Vector3(0, 2.9, 0);
    mymesh9.material = myMaterialD;
    var mymesh10 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.6, diameterTop: 0.8, diameterBottom:0.6}, scene);
    mymesh10.position = new BABYLON.Vector3(0, 3.25, 0);
    mymesh10.material = myMaterialD;

    var mymesh11 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh11.position = new BABYLON.Vector3(0, 3.65, 0);
    mymesh11.material = myMaterialD;
    var mymesh12 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.9,diameterY:0.6,diameterZ:0.9}, scene);
    mymesh12.position = new BABYLON.Vector3(0, 3.9, 0);
    mymesh12.material = myMaterialD;
    var mymesh13 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh13.position = new BABYLON.Vector3(0, 4.15, 0);
    mymesh13.material = myMaterialD;
    var mymesh14 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.3, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh14.position = new BABYLON.Vector3(0, 4.4, 0);
    mymesh14.material = myMaterialD;
    var mymesh15 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 1.0, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh15.position = new BABYLON.Vector3(0, 5.05, 0);
    mymesh15.material = myMaterialD;
    var mymesh16 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 1.2, diameterBottom:1.2}, scene);
    mymesh16.position = new BABYLON.Vector3(0, 5.6, 0);
    mymesh16.material = myMaterialD;
    var mymesh17 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 1.2, diameterBottom:1.2}, scene);
    mymesh17.position = new BABYLON.Vector3(0, 5.75, 0);
    mymesh17.material = myMaterialD;

    var mymesh18 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.2, width: 1.0, depth: 1}, scene);
    mymesh18.position = new BABYLON.Vector3(0, 1.38, -0.5);
    mymesh18.material = myMaterialE;
    var mymesh19 = BABYLON.MeshBuilder.CreateBox("myBox1", {height: 0.2, width: 1.0, depth: 1}, scene);
    mymesh19.position = new BABYLON.Vector3(0, 5.05, -0.5);
    mymesh19.material = myMaterialE;

    var myBox9 = BABYLON.Mesh.MergeMeshes([mymesh420,mymesh4,mymesh5,mymesh6,mymesh7,mymesh8,mymesh9,mymesh10,mymesh11,mymesh12,mymesh13,mymesh14,mymesh15,mymesh16,mymesh17,mymesh18,mymesh19], true, true, undefined, false, true);
    myBox9.rotation.x = -Math.PI/2;
    myBox9.position = new BABYLON.Vector3(0, 1.45, 3.1);

    // 5、两根长柱（小）
    var mymesh20 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 10.5, diameterTop: 0.3, diameterBottom:0.3}, scene);
    mymesh20.position = new BABYLON.Vector3(0, 6.8, 0.6);
    mymesh20.material = myMaterialD;
    var mymesh21 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.5, diameterBottom:0.5}, scene);
    mymesh21.position = new BABYLON.Vector3(0, 2.5, 0.6);
    mymesh21.material = myMaterialD;
    var mymesh22 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.5, diameterTop: 0.5, diameterBottom:0.3}, scene);
    mymesh22.position = new BABYLON.Vector3(0, 2.8, 0.6);
    mymesh22.material = myMaterialD;
    var mymesh23 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.7, diameterBottom:0.7}, scene);
    mymesh23.position = new BABYLON.Vector3(0, 3.1, 0.6);
    mymesh23.material = myMaterialD;
    var mymesh24 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.6,diameterY:0.5,diameterZ:0.6}, scene);
    mymesh24.position = new BABYLON.Vector3(0, 3.3, 0.6);
    mymesh24.material = myMaterialD;
    var mymesh25 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh25.position = new BABYLON.Vector3(0, 3.5, 0.6);
    mymesh25.material = myMaterialD;
    var mymesh26 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 1, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh26.position = new BABYLON.Vector3(0, 4.05, 0.6);
    mymesh26.material = myMaterialD;
    var mymesh27 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh27.position = new BABYLON.Vector3(0, 4.6, 0.6);
    mymesh27.material = myMaterialD;
    var mymesh28 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 7.5, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh28.position = new BABYLON.Vector3(0, 8.2, 0.6);
    mymesh28.material = myMaterialD; 
    var mymesh29 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh29.position = new BABYLON.Vector3(0, 5.65, 0.6);
    mymesh29.material = myMaterialD;
    var mymesh30 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh30.position = new BABYLON.Vector3(0, 7, 0.6);
    mymesh30.material = myMaterialD;
    var mymesh31 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh31.position = new BABYLON.Vector3(0, 8, 0.6);
    mymesh31.material = myMaterialD;
    var mymesh32 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh32.position = new BABYLON.Vector3(0, 9, 0.6);
    mymesh32.material = myMaterialD;
    var mymesh33 = BABYLON.MeshBuilder.CreateCylinder("cone",  {height: 0.1, diameterTop: 0.9, diameterBottom:0.9}, scene);
    mymesh33.position = new BABYLON.Vector3(0, 10.5, 0.6);
    mymesh33.material = myMaterialD;

    var myBox10 = BABYLON.Mesh.MergeMeshes([mymesh20,mymesh21,mymesh22,mymesh23,mymesh24,mymesh25,mymesh26,mymesh27,mymesh28,mymesh29,mymesh30,mymesh31,mymesh32,mymesh33], true, true, undefined, false, true);

    
    // 6、两根长柱（大）
    var mymesh40 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 10.5, diameterTop: 0.6, diameterBottom:0.6}, scene);
    mymesh40.position = new BABYLON.Vector3(0, 6.8, -1.8);
    mymesh40.material = myMaterialD;
    var mymesh41 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 1.0, diameterBottom:1.0}, scene);
    mymesh41.position = new BABYLON.Vector3(0, 2.3, -1.8);
    mymesh41.material = myMaterialD;
    var mymesh42 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh42.position = new BABYLON.Vector3(0, 2.45, -1.8);
    mymesh42.material = myMaterialD;
    var mymesh43 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh43.position = new BABYLON.Vector3(0, 3.0, -1.8);
    mymesh43.material = myMaterialD;
    var mymesh44 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.7,diameterY:0.5,diameterZ:0.7}, scene);
    mymesh44.position = new BABYLON.Vector3(0, 3.2, -1.8);
    mymesh44.material = myMaterialD;
    var mymesh45 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh45.position = new BABYLON.Vector3(0, 3.4, -1.8);
    mymesh45.material = myMaterialD;
    var mymesh46 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh46.position = new BABYLON.Vector3(0, 5.0, -1.8);
    mymesh46.material = myMaterialD;
    var mymesh47 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh47.position = new BABYLON.Vector3(0, 8.0, -1.8);
    mymesh47.material = myMaterialD;
    var mymesh48 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameterTop: 0.8, diameterBottom:0.8}, scene);
    mymesh48.position = new BABYLON.Vector3(0, 10.0, -1.8);
    mymesh48.material = myMaterialD;


    var myBox11 = BABYLON.Mesh.MergeMeshes([mymesh40,mymesh41,mymesh42,mymesh43,mymesh44,mymesh45,mymesh46,mymesh47,mymesh48], true, true, undefined, false, true);

    // 返回总的
    var myPump = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3,myBox4,myBox5,myBox6,myBox7,myBox8,myBox9,myBox10,myBox11], true, true, undefined, false, true);
    myPump.position = new BABYLON.Vector3(6, 0, 3.95);

    var myPump2 = myPump.clone("");
    var myPump3 = myPump.clone("");
    var myPump4 = myPump.clone("");
    myPump2.position = new BABYLON.Vector3(3, 0, 3.95);
    myPump3.position = new BABYLON.Vector3(0, 0, 3.95);
    myPump4.position = new BABYLON.Vector3(-3, 0, 3.95);

    return myPump;
}


function createCoolingTower(scene)
{
    //1、塔身
    var myBox1 = BABYLON.MeshBuilder.CreateBox("myBox", {height: 4, width: 6, depth: 4}, scene);
    myBox1.position = new BABYLON.Vector3(0, 2, 0);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M15.jpg", scene); 
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myBox1.material = myMaterial;//mesh是之前创建的物体

    //2、上面圆柱1
    var myBox2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.2, diameterTop: 3.7, diameterBottom:3.9, tessellation:64}, scene);
    myBox2.position = new BABYLON.Vector3(0, 4.1, 0);
    myBox2.material = myMaterial;//mesh是之前创建的物体

    //3、上面圆柱2
    var mOuter = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.3, diameterTop: 3.7, diameterBottom:3.7, tessellation:64}, scene);
    var mInner = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.4, diameterTop: 3.6, diameterBottom:3.6, tessellation:64}, scene);

    const outerCSG = BABYLON.CSG.FromMesh(mOuter);
    const innerCSG = BABYLON.CSG.FromMesh(mInner);
    const pipeCSG = outerCSG.subtract(innerCSG);
    const myBox3 = pipeCSG.toMesh("mPipe", null, scene);
    mInner.dispose();
    mOuter.dispose();
    scene.removeMesh(mInner);
    scene.removeMesh(mOuter);
    myBox3.position = new BABYLON.Vector3(0, 4.35, 0);
    myBox3.material = myMaterial;//mesh是之前创建的物体



    //4、风扇
    var myBox4 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.6, diameterTop: 0.5, diameterBottom:0.5}, scene);
    myBox4.position = new BABYLON.Vector3(0, 4.35, 0);
    var myMaterialB = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterialB.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M17.jpg", scene); 
    myMaterialB.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterialB.diffuseTexture.vScale = 1.0;//水平方向重复5次
    myBox4.material = myMaterialB;//mesh是之前创建的物体

    var myBox5 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:3.6, diameterY:0.2, diameterZ:0.4}, scene);
    myBox5.position = new BABYLON.Vector3(0, 4.35, 0);
    myBox5.material = myMaterialB;//mesh是之前创建的物体
    myBox5.rotation.x = 30*Math.PI/2/90;
    var myBox6 = BABYLON.MeshBuilder.CreateSphere("sphere", {diameterX:0.4, diameterY:0.2, diameterZ:3.6}, scene);
    myBox6.position = new BABYLON.Vector3(0, 4.35, 0);
    myBox6.material = myMaterialB;//mesh是之前创建的物体
    myBox6.rotation.z = 30*Math.PI/2/90;

    var fan1 = BABYLON.Mesh.MergeMeshes([myBox4,myBox5,myBox6], true, true, undefined, false, true);
    setAnimationRotation(scene, fan1);



    var Tower1 = BABYLON.Mesh.MergeMeshes([myBox1,myBox2,myBox3], true, true, undefined, false, true);
    Tower1.position = new BABYLON.Vector3(-4, 15, -16);
    var Tower2 = Tower1.clone("");
    Tower2.position = new BABYLON.Vector3(-4, 15, -20.1);
    var Tower3 = Tower1.clone("");
    Tower3.position = new BABYLON.Vector3(-4, 15, -24.2);
    var Tower4 = Tower1.clone("");
    Tower4.position = new BABYLON.Vector3(-4, 15, -28.3);
    var Tower5 = Tower1.clone("");
    Tower5.position = new BABYLON.Vector3(-4, 15, -32.4);

    fan1.position = new BABYLON.Vector3(-4, 15, -16);
    var fan2 = fan1.clone("");
    fan2.position = new BABYLON.Vector3(-4, 15, -20.1);
    setAnimationRotation(scene, fan2);
    var fan3 = fan1.clone("");
    fan3.position = new BABYLON.Vector3(-4, 15, -24.2);
    setAnimationRotation(scene, fan3);
    var fan4 = fan1.clone("");
    fan4.position = new BABYLON.Vector3(-4, 15, -28.3);
    setAnimationRotation(scene, fan4);
    var fan5 = fan1.clone("");
    fan5.position = new BABYLON.Vector3(-4, 15, -32.4);
    setAnimationRotation(scene, fan5);


    // 创建粒子
    createParticleSystem(scene, fan1);
    createParticleSystem(scene, fan3);
    createParticleSystem(scene, fan5);

    return fan1;

}

function setAnimationRotation(scene, myBox7)
{
//   //动画 —— 创建一个位置的三维向量Vector3动画， FPS为30
//   var animationTorus = new BABYLON.Animation("torusEasingAnimation", "rotation", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
//   var nextPos = myBox7.rotation.add(new BABYLON.Vector3(0, Math.PI*8, 0));
//   var keysTorus = [];
//   keysTorus.push({ frame: 0, value: myBox7.rotation });
//   keysTorus.push({ frame: 120, value: nextPos });
//   animationTorus.setKeys(keysTorus);
//   var easingFunction = new BABYLON.CircleEase();
//   easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
//   animationTorus.setEasingFunction(easingFunction);
//   myBox7.animations.push(animationTorus);
//   scene.beginAnimation(myBox7, 0, 120, true); 

  //动画 —— 创建一个位置的三维向量Vector3动画， FPS为30
  var animationTorus = new BABYLON.Animation("torusEasingAnimation", "rotation", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
  var nextPos = myBox7.rotation.add(new BABYLON.Vector3(0, Math.PI*2, 0));
  var keysTorus = [];
  keysTorus.push({ frame: 0, value: myBox7.rotation });
  keysTorus.push({ frame: 30, value: nextPos });
  animationTorus.setKeys(keysTorus);
// 快慢结合
//   var easingFunction = new BABYLON.CircleEase();
//   easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
//   animationTorus.setEasingFunction(easingFunction);
  myBox7.animations.push(animationTorus);
  scene.beginAnimation(myBox7, 0, 30, true); 
}

// 创建粒子系统
function createParticleSystem(scene, myBox7)
{

            // 创建粒子系统
            var particleSystem = new BABYLON.ParticleSystem(myBox7, 2000, scene);

            // 每个粒子的纹理
            particleSystem.particleTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P6.png", scene);

            // 粒子从何而来
            particleSystem.emitter = myBox7;//new BABYLON.Vector3(0, 10, 0); // the starting object, the emitter
            // particleSystem.minEmitBox = new BABYLON.Vector3(-1, 0, 0); // Starting all from
            // particleSystem.maxEmitBox = new BABYLON.Vector3(1, 0, 0); // To...

            // 所有粒子的颜色
            particleSystem.color1 = new BABYLON.Color4(0.7, 0.8, 1.0, 1.0);
            particleSystem.color2 = new BABYLON.Color4(0.2, 0.5, 1.0, 1.0);
            particleSystem.colorDead = new BABYLON.Color4(0, 0, 0.2, 0.0);

            // 每个粒子的大小（随机...
            particleSystem.minSize = 0.05;
            particleSystem.maxSize = 0.1;

            // 每个粒子的寿命（随机...
            particleSystem.minLifeTime = 1;
            particleSystem.maxLifeTime = 2.5;

            // 排放率
            particleSystem.emitRate = 1500;

            // 混合模式 : BLENDMODE_ONEONE, or BLENDMODE_STANDARD
            particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;

            // 设置所有粒子的重力
            particleSystem.gravity = new BABYLON.Vector3(0, -9, 0);

            // 每个粒子发射后的方向
            particleSystem.direction1 = new BABYLON.Vector3(-2, 8, 2);
            particleSystem.direction2 = new BABYLON.Vector3(2, 8, -2);

            // 角速度，以弧度为单位
            particleSystem.minAngularSpeed = 0;
            particleSystem.maxAngularSpeed = Math.PI;

            // 速度
            particleSystem.minEmitPower = 1;
            particleSystem.maxEmitPower = 3;
            particleSystem.updateSpeed = 0.025;

            // 启动粒子系统
            particleSystem.start(); 
}

// 冷凝塔黄色管
function createCoolingTowerPipe1(scene)
{ 
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = -10.5;
    var sy = 12;
    var sz = -14;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz));   
    path.push(new BABYLON.Vector3(sx,sy+3,sz));   
    path.push(new BABYLON.Vector3(sx,sy+8,sz));     
    var radius = 0.25; 
    var r = 1;  
   
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx + r - r * Math.cos(da*i);
        let y = sy  + r * Math.sin( da*i);
        let z = sz;
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx+10, sy, sz)); 

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;

 

    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.6}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.65}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[path.length-1].x,path[path.length-1].y,path[path.length-1].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.z = Math.PI/2;

    
    // 这个水流保留前段的路径
    var path2 = [];
    let j = 0;
    while (j < path.length)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j++;
    }
    
    // 重新来。。。。。。。。。。。。。。。。。。。
    // 继续画两个插下去的小管
    radius = 0.15; 
    sx = path[path.length-1].x-2;//new BABYLON.Vector3(path[path.length-1].x-2 - 5
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path = [];
    path.push(new BABYLON.Vector3(sx, sy, sz));
    path.push(new BABYLON.Vector3(sx, sy, sz-0.75));
    path.push(new BABYLON.Vector3(sx, sy, sz-1.5));

    // 弯下去
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx;
        let y = sy  - r + r * Math.cos(da*i);
        let z = sz - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy-4, sz)); 
 
    var myPipe3=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe3.material = myMaterialS;

    var myPipe4 = myPipe3.clone("");
    myPipe4.position = new BABYLON.Vector3(myPipe4.position.x-4.8,myPipe4.position.y,myPipe4.position.z);


    // 复制5份
    var T1 = BABYLON.Mesh.MergeMeshes([myPipe2,mymesh3,myPipe3,myPipe4], true, true, undefined, true, true);
    var T2 = T1.clone("");
    T2.position = new BABYLON.Vector3(T1.position.x,T1.position.y,T1.position.z-4.1);
    var T3 = T1.clone("");
    T3.position = new BABYLON.Vector3(T1.position.x,T1.position.y,T1.position.z-8.2);
    var T4 = T1.clone("");
    T4.position = new BABYLON.Vector3(T1.position.x,T1.position.y,T1.position.z-12.3);
    var T5 = T1.clone("");
    T5.position = new BABYLON.Vector3(T1.position.x,T1.position.y,T1.position.z-16.4);

    



    // 水流1
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 4.1;
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 4.1;
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流4
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 4.1;
    createWaterLoop1(scene, path, 0.2, 0.7);
    // 水流5
    for (let i = 0; i < path.length; i++)
        path[i].z = path[i].z - 4.1;
    createWaterLoop1(scene, path, 0.2, 0.7);

    
    // 水流1
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流4
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流5
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);

    return myPipe2;
}



// 冷凝塔绿色管
function createCoolingTowerPipe2(scene)
{ 
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P4.jpg", scene);
    myMaterialS.alpha = 0.5;
    var sx = -12;
    var sy = 12;
    var sz = -16;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz));   
    path.push(new BABYLON.Vector3(sx,sy+1,sz));   
    path.push(new BABYLON.Vector3(sx,sy+3,sz));     
    var radius = 0.25; 
    var r = 1;  
   
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx + r - r * Math.cos(da*i);
        let y = sy  + r * Math.sin( da*i);
        let z = sz;
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx+5, sy, sz)); 

    var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    myPipe2.material = myMaterialS;
 

    // 复制5份
    var T2 = myPipe2.clone("");
    T2.position = new BABYLON.Vector3(myPipe2.position.x,myPipe2.position.y,myPipe2.position.z-4.1);
    var T3 = myPipe2.clone("");
    T3.position = new BABYLON.Vector3(myPipe2.position.x,myPipe2.position.y,myPipe2.position.z-8.2);
    var T4 = myPipe2.clone("");
    T4.position = new BABYLON.Vector3(myPipe2.position.x,myPipe2.position.y,myPipe2.position.z-12.3);
    var T5 = myPipe2.clone("");
    T5.position = new BABYLON.Vector3(myPipe2.position.x,myPipe2.position.y,myPipe2.position.z-16.4);

    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流2
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流3
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流4
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    // 水流5
    for (let i = 0; i < path2.length; i++)
    path2[i].z = path2[i].z - 4.1;
    createWaterLoop1(scene, path2, 0.2, 0.7);
    return myPipe2;
}


async function  createBox(scene)
{
    //添加立方体
    var myBox = BABYLON.MeshBuilder.CreateBox("myBox", {height: 2, width: 1, depth: 0.5}, scene);
    myBox.position = new BABYLON.Vector3(0, 1, 0);
    
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M33.jpg", scene); 
    myMaterial.diffuseTexture.uScale = 2.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 2.0;//水平方向重复5次
    myBox.material = myMaterial;//mesh是之前创建的物体
    return myBox;
}

async function  createSphere(scene)
{
    // 添加球体
    //BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:2, diameterX: 3,arc:0.5}, scene);
    var mymesh = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:2}, scene);
    mymesh.position = new BABYLON.Vector3(3, 1, 0);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M16.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}

async function  createSphere2(scene)
{
    // 添加球体-骨架
    //BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:2, diameterX: 3,arc:0.5}, scene);
    var mymesh = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter:2, diameterX: 3,slice:0.5}, scene);
    mymesh.position = new BABYLON.Vector3(0, 1, 0);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质
    myMaterial.wireframe = true; 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M21.jpg", scene);
    myMaterial.diffuseTexture.uScale = 40.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 40.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}


async function  createCylinder1(scene)
{
    // 添加圆柱
    var mymesh = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 5, diameterTop: 1, diameterBottom:2}, scene);
    mymesh.position = new BABYLON.Vector3(-13, 10, 0);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M26.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体

    // var gizmoManager = new BABYLON.GizmoManager(scene);
    // gizmoManager.positionGizmoEnabled = true;
    // //gizmoManager.rotationGizmoEnabled = true;
    // gizmoManager.scaleGizmoEnabled = true;
    // //gizmoManager.boundingBoxGizmoEnabled = true;
    // gizmoManager.attachableMeshes = [mymesh];
    
    // 1、拖动（拉拽）
    var utilLayer = new BABYLON.UtilityLayerRenderer(scene);
    var gizmox = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(1,0,0), BABYLON.Color3.Red(), utilLayer);
    gizmox.attachedMesh = mymesh;
    gizmox.dragBehavior.onDragObservable.add(()=>{
        console.log(mymesh.position);
    })
    var gizmoy = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(0,1,0), BABYLON.Color3.Green(), utilLayer);
    gizmoy.attachedMesh = mymesh;
    gizmoy.dragBehavior.onDragObservable.add(()=>{
        console.log(mymesh.position);
    })
    var gizmoz = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(0,0,1), BABYLON.Color3.Blue(), utilLayer);
    gizmoz.attachedMesh = mymesh;
    gizmoz.dragBehavior.onDragObservable.add(()=>{
        console.log(mymesh.position);
    })

    // 2、GUI
    var advancedTexture = BABYLONUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

    var button1 = BABYLONUI.Button.CreateImageWithCenterTextButton("but1", "Click Me");
    button1.width = "150px"
    button1.height = "30px";
    button1.color = "white";
    button1.size = "10px";
    button1.cornerRadius = 20;
    button1.background = "green";
    button1.onPointerUpObservable.add(function() {
        alert("you did it!");
    });
    button1.alpha = 0.5;
    advancedTexture.addControl(button1);    


    // 3GUI
    var plane = BABYLON.Mesh.CreatePlane("plane", 10);
    plane.position.y = 3;
    var advancedTexture2 = BABYLONUI.AdvancedDynamicTexture.CreateForMesh(plane);   
      var button2 = BABYLONUI.Button.CreateSimpleButton("but1", "您好，欢迎EMC");
      button2.width = "250px"
      button2.height = "50px";
      button2.color = "white";
      button2.fontSize = 30;
      button2.cornerRadius = 20;
      button2.background = "green";
      button2.onPointerUpObservable.add(function() {
          alert("you did it!");
      });
      advancedTexture2.addControl(button2);  

      // 4linkWithMesh 
      var button3 = BABYLONUI.Button.CreateSimpleButton("but1", "跟随");
      button3.width = "150px"
      button3.height = "30px";
      button3.color = "white";
      button3.size = "10px";
      button3.cornerRadius = 20;
      button3.background = "green";
      button3.onPointerUpObservable.add(function() {
          alert("you did it!");
      });
      //advancedTexture.linkWithMesh(mymesh);   
      
         // GUI

    var button = BABYLONUI.Button.CreateImageWithCenterTextButton("but", "欢迎访问达实", "textures/grass.png");
    const uiPanel = new BABYLONUI.StackPanel();
    uiPanel.width = "120px";
    uiPanel.fontSize = "14px";
    uiPanel.horizontalAlignment = BABYLONUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    uiPanel.verticalAlignment = BABYLONUI.Control.VERTICAL_ALIGNMENT_CENTER;
    advancedTexture.addControl(uiPanel);

    button.width = 1;
    button.height = "40px";
    button.color = "white";
    button.background = "green";
    //button.position = new BABYLON.Vector3(3,3,30);
    //advancedTexture.addControl(button);   
    uiPanel.addControl(button);
    return mymesh;
}

async function  createCylinder2(scene)
{
    // 添加圆锥
    var mymesh = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 2, diameterTop: 0, diameterBottom:0.8}, scene);
    mymesh.position = new BABYLON.Vector3(-1.5, 1, 0);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M26.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}


async function  createDisc(scene)
{
    // 添加圆盘或多边形
    var mymesh = BABYLON.MeshBuilder.CreateDisc("disc", {radius:0.5, tessellation: 40,sideOrientation:2}, scene); 
    mymesh.position = new BABYLON.Vector3(-1.5, 1, -3);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M17.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}



async function  createTorus(scene)
{
    // 圆环
    var mymesh =  BABYLON.MeshBuilder.CreateTorus("torus222", {thickness: 0.3,diameter:2,tessellation:50}, scene);
    mymesh.position = new BABYLON.Vector3(1.5, 1, -3);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M15.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    mymesh.isPickable = true;
    return mymesh;
}



async function  createTorusKnot(scene)
{
    // 圆面扭结
    var mymesh =  BABYLON.MeshBuilder.CreateTorusKnot("tk", {radius:1,tube:0.3,radialSegments:100}, scene);
    mymesh.position = new BABYLON.Vector3(1.5, 2, -6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M15.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}
 


async function  createribbon(scene)
{
    // 纽带 
    // paths
    var path1;
    var path2;
    var path3;
    var path4;
    path1 = [];
    path2 = [];
    path3 = [];
    path4 = [];
    for (var i = -5; i < 6; i++) {
        path1.push( new BABYLON.Vector3(i, 2, 0) );
        path2.push( new BABYLON.Vector3(i, 1, 0) );
        path3.push( new BABYLON.Vector3(i-1, -2, -1) );
        path4.push( new BABYLON.Vector3(i+1, -4, 1) );
    }
    // for( var i = 6; i < 10; i++ ) {
    // 	path2.push( new BABYLON.Vector3(i, 1, 0));
    // 	path3.push( new BABYLON.Vector3(i-1, -2, -1));
    // }
        
    var mymesh = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: [path1, path2, path3, path4],sideOrientation:2}, scene);
        
    mymesh.position = new BABYLON.Vector3(1.5, 4, 6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M05.jpg", scene);
    myMaterial.diffuseTexture.uScale = 4.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 4.0;//水平方向重复5次
    myMaterial.wireframe = true;
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}

async function  createribbon2(scene)
{
    // 纽带 

    var paths = [];
    var disp = 10;
    var radius = 4;
    var steps = 60;
    var step = 2 * Math.PI / steps;
    var circle =[];

    for(let i = 0; i < 3 * Math.PI /2; i += step) {
        let x = radius * Math.cos(i) + disp;
        let y = radius * Math.sin(i);
        let z = 0;
        circle.push(new BABYLON.Vector3(x, y, z));
    }

    var deltaSteps = 40;
    var delta = 2 * Math.PI / deltaSteps;
    for(var p = 0; p< 7 * Math.PI / 4; p += delta) {
        var path = [];
        for( let i = 0; i < circle.length; i++) {
            var x = circle[i].x * Math.cos(p) + circle[i].z * Math.sin(p);
            var y = circle[i].y;
            var z = -circle[i].x * Math.sin(p) + circle[i].z * Math.cos(p);
            path.push(new BABYLON.Vector3(x, y, z));
        }
        paths.push(path);
    }

    var mymesh = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: paths, closePath: true }, scene);
    mymesh.position = new BABYLON.Vector3(1.5, 8, 6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M05.jpg", scene);
    myMaterial.diffuseTexture.uScale = 4.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 4.0;//水平方向重复5次
    myMaterial.wireframe = true;
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}


async function  createSubtract(scene)
{
    var diamInner = 2;
    var diamOuter = 2.1;
    var length = 4;
    // 两个物体相减
    const mOuter = BABYLON.MeshBuilder.CreateCylinder(
    "mOuter",
    {
        diameter: diamOuter,
        height: length,
    },
    scene,
    );
    // Create the inner wall using a Tube mesh
    const mInner = BABYLON.MeshBuilder.CreateCylinder(
    "mInner",
    {
        diameter: diamInner,
        height: length,
    },
    scene,
    );
    const outerCSG = BABYLON.CSG.FromMesh(mOuter);
    const innerCSG = BABYLON.CSG.FromMesh(mInner);
    const pipeCSG = outerCSG.subtract(innerCSG);
    const mymesh = pipeCSG.toMesh("mPipe", null, scene);
    mInner.dispose();
    mOuter.dispose();
    scene.removeMesh(mInner);
    scene.removeMesh(mOuter);
    // Return the result
    //return mPipe; 
 
    mymesh.position = new BABYLON.Vector3(0, 2, -6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M34.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}



async function  createIntersect(scene)
{ 
    // 两个物体相交
    const mOuter = BABYLON.MeshBuilder.CreateSphere( "mOuter",   {diameter:5},  scene);
    const mInner = BABYLON.MeshBuilder.CreateCylinder( "mInner", { diameter: 2, height: 12},scene );
    const outerCSG = BABYLON.CSG.FromMesh(mOuter);
    const innerCSG = BABYLON.CSG.FromMesh(mInner);
    const pipeCSG = outerCSG.intersect(innerCSG);
    const mymesh = pipeCSG.toMesh("mPipe", null, scene);
    mInner.dispose();
    mOuter.dispose();
    scene.removeMesh(mInner);
    scene.removeMesh(mOuter);
 
    mymesh.position = new BABYLON.Vector3(-4, 2, -6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M34.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}


function  createUnion(scene)
{ 
    // 两个物体合并
    const mOuter = BABYLON.MeshBuilder.CreateSphere( "mOuter",   {diameter:2},  scene);
    mOuter.position = new BABYLON.Vector3(0, 7, 0);
    const mInner = BABYLON.MeshBuilder.CreateCylinder( "mInner", { diameter: 2, height: 12},scene );
    // const outerCSG = BABYLON.CSG.FromMesh(mOuter);
    // const innerCSG = BABYLON.CSG.FromMesh(mInner);
    // const pipeCSG = outerCSG.union(innerCSG);
    // const mymesh = pipeCSG.toMesh("mPipe", null, scene);
    // mInner.dispose();
    // mOuter.dispose();
    // scene.removeMesh(mInner);
    // scene.removeMesh(mOuter);
    var mymesh = BABYLON.Mesh.MergeMeshes([mOuter,mInner], true, true, undefined, false, true);
     
    mymesh.position = new BABYLON.Vector3(4, 2, -6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M34.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}



async function  createKele(scene)
{ 
	var canMaterial = new BABYLON.StandardMaterial("material", scene);
	canMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M36.jpg", scene)
	var faceUV = [];
    //向量（左下 x，左下 y，右上 x，右上 y）
	faceUV[0] =	new BABYLON.Vector4(0, 0, 0, 0);
    faceUV[1] =	new BABYLON.Vector4(1, 0, 0.32, 1);
    faceUV[2] = new BABYLON.Vector4(0, 0, 0.25, 1);

    var faceColors = [ ];
    faceColors[0] = new BABYLON.Color4(0.5, 0.5, 0.5, 1)
	
	var can = BABYLON.MeshBuilder.CreateCylinder("can", {height:1.16, faceUV: faceUV, faceColors: faceColors}, scene);
	can.material = canMaterial;
    can.position = new BABYLON.Vector3(4, 0.5, -6);
    return can;
}



async function  animation1(scene)
{ 
    // 圆环
    var mymesh =  BABYLON.MeshBuilder.CreateTorus("torus", {thickness: 0.3,diameter:2,tessellation:50}, scene);
    mymesh.position = new BABYLON.Vector3(1.5, 4, -3);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M15.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    
    //创建一个位置的三维向量Vector3动画， FPS为30
    var animationTorus = new BABYLON.Animation("torusEasingAnimation", "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

    // 圆环的目标位置
    var nextPos = mymesh.position.add(new BABYLON.Vector3(-80, 0, 0));

    // 动画的keys
    var keysTorus = [];
    keysTorus.push({ frame: 0, value: mymesh.position });
    keysTorus.push({ frame: 120, value: nextPos });
    animationTorus.setKeys(keysTorus);

    // 穿件缓动函数CircleEase
    var easingFunction = new BABYLON.CircleEase();
    //对于每个缓动函数，可以选择 EASEIN (默认), EASEOUT, EASEINOUT三种
    easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    // 添加缓动函数到动画中
    animationTorus.setEasingFunction(easingFunction);
    
    // //创建一个贝塞尔曲线函数
    // var bezierEase = new BABYLON.BezierCurveEase(0.32, -0.73, 0.69, 1.59);
    // //为动画对象设置一个缓动函数
    // animationTorus.setEasingFunction(bezierEase);

    // 向圆环中的动画集合中添加动画实例
    mymesh.animations.push(animationTorus);

    // 最后，执行圆环动画，从0到120帧，循环播放
    scene.beginAnimation(mymesh, 0, 120, true); 
}



function  createMaterial(scene)
{  
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M09.jpg", scene);
    myMaterial.diffuseTexture.uScale = 5.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 5.0;//水平方向重复5次 
    return myMaterial;
}



function  createTube1(scene)
{ 
    // 管子1（可以降低一段弯度）
    var path=[]
    for(let i=0;i<20;i++){
        path.push(new BABYLON.Vector3(i,0,0));
    }
    var r = 1; 
    // for(let i=20;i<30;i++){
    //     path.push(new BABYLON.Vector3(i,0-(i-20)*0.3,0));
    // }   
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var da = Math.PI/2/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx + r * Math.cos(Math.PI/2 - da*i);
        let y = sy + r * Math.sin(Math.PI/2 -da*i) - r;
        path.push(new BABYLON.Vector3(x,y,0));
    } 
    // 第二个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    for (let i = 0; i< 20; i++) 
    {
        console.log(r * Math.cos(da*i));
        let x = sx + (r - r * Math.cos(da*i));
        let y = sy - r * Math.sin(da*i)  ;
        path.push(new BABYLON.Vector3(x,y,0));
    }
    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    for(let i=0;i<20;i++){
        path.push(new BABYLON.Vector3(sx + i, sy, 0));
    }

    var mymesh=BABYLON.Mesh.CreateTube('tube',path,0.5,16,null,0,scene,true,2);
 
    //console.log(Math.sin(Math.PI/2));
    //console.log(Math.sin(0));

    mymesh.position = new BABYLON.Vector3(-4, 6, -6);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M34.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}



function  createTube2(scene)
{ 
    // 管子2（135度弯）
    var path=[]
    for(let i=0;i<10;i++){
        path.push(new BABYLON.Vector3(i,0,0));
    }
    var r = 1; 
    // for(let i=20;i<30;i++){
    //     path.push(new BABYLON.Vector3(i,0-(i-20)*0.3,0));
    // }   
    // 第一个弧度
    var sx = path[path.length-1].x;
    var sy = path[path.length-1].y;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx + r * Math.sin(da*i);
        let y = sy + r - Math.cos(da*i);
        path.push(new BABYLON.Vector3(x,y,0));
    }  
    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    for(let i=0;i<10;i++){
        path.push(new BABYLON.Vector3(sx + i, sy + i, 0));
    }

    var mymesh=BABYLON.Mesh.CreateTube('tube',path,0.5,16,null,0,scene,true,2);
 
    //console.log(Math.sin(Math.PI/2));
    //console.log(Math.sin(0));

    mymesh.position = new BABYLON.Vector3(0, 1, -10);
    var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterial.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/M34.jpg", scene);
    myMaterial.diffuseTexture.uScale = 1.0;//垂直方向重复5次
    myMaterial.diffuseTexture.vScale = 1.0;//水平方向重复5次
    mymesh.material = myMaterial;//mesh是之前创建的物体
    return mymesh;
}



function createPipeTest1(scene)
{
    var myMaterialS = new BABYLON.StandardMaterial("myMaterial", scene);//创建一个材质 
    myMaterialS.diffuseTexture = new BABYLON.Texture("https://s.mitcs.cn/das/P3.jpg", scene);
    myMaterialS.alpha = 1;
    var sx = 10;
    var sy = 10;
    var sz = 20;
    var path=[]; 
    path.push(new BABYLON.Vector3(sx,sy,sz+4));   
    path.push(new BABYLON.Vector3(sx,sy,sz));     
    path.push(new BABYLON.Vector3(sx,sy,sz-1));  // 变大  2
    path.push(new BABYLON.Vector3(sx,sy,sz-1.5));   
    path.push(new BABYLON.Vector3(sx,sy,sz-2));       
    path.push(new BABYLON.Vector3(sx,sy,sz-3));      
    path.push(new BABYLON.Vector3(sx,sy,sz-4));    
    var radius = 0.35; 
    var r = 1;  
    // 第0个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    var da = 45*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx  ;
        let y = sy + r - r * Math.cos(da*i);
        let z = sz  - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  


    // 后面长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy+5, sz-5)); 
    
    
    // 第一个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) 
    { 
        let x = sx - r + r * Math.cos(da*i);
        let y = sy ;
        let z = sz  - r * Math.sin( da*i);
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间小弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx-1, sy, sz-1)); 

    // 第二个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 45*Math.PI/2/90/5;
    for (let i = 0; i< 5; i++) 
    { 
        let x = sx - Math.abs( r * Math.cos(45*Math.PI/2/90) -  r * Math.cos(45*Math.PI/2/90+da*i));
        let y = sy ;
        let z = sz  - Math.abs( r * Math.sin(45*Math.PI/2/90) - r * Math.sin(45*Math.PI/2/90 + da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
 

    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(-10, sy, sz)); 
    
    
    // 第三个弧度
    sx = path[path.length-1].x;
    sy = path[path.length-1].y;
    sz = path[path.length-1].z;
    da = 90*Math.PI/2/90/20;
    for (let i = 0; i< 20; i++) 
    { 
        let x = sx - r * Math.sin(da*i);
        let y = sy ;
        let z = sz  - (r - r * Math.cos( da*i));
        path.push(new BABYLON.Vector3(x,y,z));
    }  
    // 中间大弯曲长度    
    sx = path[path.length-1].x;
    sy = path[path.length-1].y; 
    sz = path[path.length-1].z; 
    path.push(new BABYLON.Vector3(sx, sy, sz-26.5)); 
    console.log(path);

    //var myPipe2=BABYLON.Mesh.CreateTube('tube',path,radius,16,null,0,scene,true,2);
    var myPipe2=BABYLON.MeshBuilder.CreateTube('tube',{path:path ,radius :radius,tessellation :32,radiusFunction :getRadius,arc:1,cap:BABYLON.Mesh.CAP_START,updatable:true,sideOrientation:2},scene);
    myPipe2.material = myMaterialS;


    // 前端两个帽子
    var mymesh1 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.15, diameter: 0.8}, scene);
    var mymesh2 = BABYLON.MeshBuilder.CreateCylinder("cone", {height: 0.1, diameter: 0.85}, scene);
    mymesh2.position = new BABYLON.Vector3(0,0.125,0);
    var mymesh3 = BABYLON.Mesh.MergeMeshes([mymesh1,mymesh2], true, true, undefined, true, true);
    mymesh3.position = new BABYLON.Vector3(path[0].x,path[0].y,path[0].z);
    mymesh3.material = myMaterialS;
    mymesh3.rotation.x = -Math.PI/2;

    var mymesh4 = mymesh3.clone();
    mymesh4.position = new BABYLON.Vector3(path[path.length-1].x,path[path.length-1].y,path[path.length-1].z);
    mymesh4.rotation.z = -Math.PI/2;

    
    
    // 这个水流是反向的，需要逆序
    var path2 = [];
    let j = path.length-1;
    while (j >= 0)
    {
        path2.push(new BABYLON.Vector3(path[j].x,path[j].y,path[j].z));
        j--;
    }
    // 水流1
    createWaterLoop1(scene, path2, 0.3, 1);
    return myPipe2;
}

function getRadius(index, distance)
{
    if (index <= 2)
        return  0.35; 
    else if ( index == 3 || index == 4)
         return  0.5; 
    else if  ( index > 4 && index <= 40)
        return  0.5;
    else return 0.1;

    // if ( index == 2 || index == 3 || index == 30)
    //     return  0.6;
    // else return  0.35;
}

export { //很关键
    createSky1,createSky2,createSky3,createGround,createFence,createDrainage,createWaterChiller,createZhengFaUp,createZhengFaDown,createLengNingDown,createLengNingUp,
    createZhengFaPipeUp,createZhengFaPipeDown,createLengNingPipeUp,createLengNingPipeDown,createLengNingPipeDown2,createWaterPump1,createWaterPump2,createZhengFaPipeDown2,
    createCoolingTower,createCoolingTowerPipe1,createCoolingTowerPipe2,createPipeTest1,

    createSphere,createSphere2,createCylinder1,createCylinder2,createDisc,createTorus,createTorusKnot,createribbon,
    createribbon2,createSubtract,createIntersect,createUnion,createKele,animation1,createMaterial,createBox,createTube1,createTube2,

    crateNationalFlag,
} 

 