<template>
  <canvas id="renderCanvas"  @click="click()">  </canvas>
</template>

<script>  
import {ref,reactive} from "vue";
import * as BABYLON from "babylonjs" ;
import * as BABYLONUI from "babylonjs/babylon.gui.min.js" ;
import  {createSky1,createSky2,createSky3,createGround,createFence,createDrainage,createWaterChiller,createZhengFaUp,createZhengFaDown,createLengNingDown,createLengNingUp,
    createZhengFaPipeUp,createZhengFaPipeDown,createLengNingPipeUp,createLengNingPipeDown,createLengNingPipeDown2,createWaterPump1,createWaterPump2,createZhengFaPipeDown2,
    createCoolingTower,createCoolingTowerPipe1,createCoolingTowerPipe2,createPipeTest1,
    
    createBox,createSphere,createSphere2,createCylinder1,createCylinder2,createDisc,createTorus,createTorusKnot,createribbon,createribbon2,
        createSubtract,createIntersect,createUnion,createKele,animation1,createMaterial,createTube1,createTube2,
        crateNationalFlag}  from '/src/assets/3djs/mesh_anpeilong.js'
var _this;
export default {
    data(){
        return {
            showmodel:"1",
            scene2:null,
            canvas2:null,
        }
    },
    props:["clickevent","showmodel2"],
    created:function(){
        _this = this;
    },
    mounted:function(){
            
        var selectedMesh, newMaterial, oldMaterial, isMove=false;
        
        var canvas = document.getElementById("renderCanvas");  
        canvas.addEventListener("mousemove",function(mouse){
            console.log("当前坐标为："+mouse.x+ ","+mouse.y);
        },false);
        var engine = new BABYLON.Engine(canvas, true); 
        var createScene = function () {
            var scene = new BABYLON.Scene(engine); 
            _this.scene2 = scene;
            _this.canvas2 = canvas;

            //1、弧形旋转相机
            var camera = new BABYLON.ArcRotateCamera("CameraAAA", Math.PI / 2, Math.PI / 2, 3, new BABYLON.Vector3(35,3.5,35), scene);
            camera.setTarget( new BABYLON.Vector3(0,0,0));

            //console.log(scene.cameras.length);
            //console.log(scene.cameras[0]);
            //scene.cameras.pop();
            //console.log(scene.cameras.length);
            //第一人称的移动视角
            //var camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(5, 0.1, -5), scene);
            //scene.activeCamera = camera;  // 可以解决多个相机出错的问题

            // //2、第一人称相机
            // var camera = new BABYLON.FreeCamera("FreeCamera", new BABYLON.Vector3(5, 0.1, 15), scene);
            // camera.setTarget( new BABYLON.Vector3(11.25,1.5,19));
            
            
            camera.attachControl(canvas, true);
            camera.keysUp.push(87);
            camera.keysDown.push(83);
            camera.keysLeft.push(65);
            camera.keysRight.push(68);
            camera.speed = 0.5;
            camera.inertia = 0.8;
            camera.minZ = 0.05;
            
            scene.gravity = new BABYLON.Vector3(0, -0.10, 0);
            //camera.applyGravity = true;
            camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
            // 开启碰撞检测
            scene.collisionsEnabled = true;
            camera.checkCollisions = true;
            //new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(20, 20, 0), scene);a
            var light1 = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), scene);
            light1.intensity = 1.5;
            light1.specular = new BABYLON.Color3(0.2,0.2,0.2);
            //new BABYLON.PointLight("light2", new BABYLON.Vector3(80, 80, -100), scene);
            //new BABYLON.DirectionalLight("light3", new BABYLON.Vector3(-80, 80, 100), scene);
            scene.ambientColor = new BABYLON.Color3(1, 1, 1);
            //new BABYLON.HemisphericLight("light2", new BABYLON.Vector3(0, -1, 1), scene);
            // 地面围栏
            createFence(scene);
            // 下水道
            createDrainage(scene);
            // 冷水机组123
            createWaterChiller(scene);
            // 蒸发器上面水管
            let pointList1 = createZhengFaUp(scene);
            let pointList2 = createZhengFaDown(scene);
            let pointList3 = createLengNingUp(scene);
            let pointList4 = createLengNingDown(scene);
            // 蒸发器联通管道-大
            createZhengFaPipeUp(scene,pointList1);
            createZhengFaPipeDown(scene,pointList2);
            createZhengFaPipeDown2(scene,pointList1);
            // 冷凝器联通管道-大
            createLengNingPipeUp(scene,pointList3);
            createLengNingPipeDown(scene,pointList4);
            createLengNingPipeDown2(scene,pointList4);
            // 水泵
            createWaterPump1(scene);
            createWaterPump2(scene);
            // 冷却塔
            createCoolingTower(scene);
            createCoolingTowerPipe1(scene);
            createCoolingTowerPipe2(scene);

            // 测试
            //createPipeTest1(scene);

            //console.log(pointList4);
            // // 正方体
            // createBox(scene);
            // // 骨架半球
            // createSphere2(scene);
            // // 画球
            // createSphere(scene);
            // // 圆柱
            // createCylinder1(scene);
            // // 圆锥
            // createCylinder2(scene);
            // // 圆盘或多边形
            // createDisc(scene);
            // // 圆环
            // createTorus(scene);
            // // 圆面扭结
            // createTorusKnot(scene);
            // // 纽带（多面体）
            // createribbon(scene);
            // // 纽带2（多面体）
            // createribbon2(scene);
            // // 两个物体相减
            // createSubtract(scene);
            // // 两个物体相交 (相交的算法执行很慢)
            // //createIntersect(scene);
            // // 两个物体合并(合并的算法执行更慢)
            // createUnion(scene);
            // // 可乐
            // createKele(scene);
            // // 创建一个动画
            // animation1(scene);
            // // 管子1（可以降低一段弯度）
            // createTube1(scene);
            // // 管子2（135度弯）
            // createTube2(scene);

            // 创建默认平面
            //var myPlane = BABYLON.MeshBuilder.CreatePlane("myPlane", {width: 5, height:10}, scene);
            // 创建地面
            //var myGround = BABYLON.MeshBuilder.CreateGround("myGround", {width: 6, height: 4, subdivisions: 1}, scene);
            var ground = createGround(scene,engine);
            ground.checkCollisions = true; 
            var sky = createSky2(scene);

            // 国旗
            crateNationalFlag(scene);
            //createCylinder1(scene);

            return scene;
        };
        var scene = createScene(); // 首先调用它并赋值给一个变量
        engine.runRenderLoop(function () {
            console.log("帧率："+engine.getFps().toFixed() + " FPS");
            scene.render();
        });
        window.addEventListener("resize", function () {
                engine.resize();
        });
        //当点击事件被触发时
        window.addEventListener("click", function () { 
            var pickResult = scene.pick(scene.pointerX, scene.pointerY);
            if (pickResult.hit) {  
                if (pickResult.pickedMesh)
                { 
                    //selectedMesh, newMaterial, oldMaterial
                    if (!newMaterial)
                        newMaterial = createMaterial();
                    if (selectedMesh != pickResult.pickedMesh)
                    {
                        if (selectedMesh)
                            selectedMesh.material = oldMaterial;
                        oldMaterial = pickResult.pickedMesh.material;
                        if (pickResult.pickedMesh.name != "myGround" && pickResult.pickedMesh.name != "MyDrainage"  && pickResult.pickedMesh.name != "skyBox"  )
                        {
                            pickResult.pickedMesh.material = newMaterial;
                            //console.log(pickResult.pickedMesh.material.alpha);
                            //pickResult.pickedMesh.material.alpha = 0.5; 
                            //pickResult.pickedMesh.material.alpha = 0.5;
                            //console.log(pickResult.pickedMesh.material.alpha);
                        }
                        selectedMesh = pickResult.pickedMesh;
                    }
                } 
            }
            else if (selectedMesh)
            {
                selectedMesh.material = oldMaterial;
                selectedMesh = null;
            }
        });


    },
    watch: {
        showmodel2: function(val) { 
            var camera = null;
            //_this.scene2.cameras.pop();
            if (val == "EMC2")
            {
                //1、弧形旋转相机
                camera = new BABYLON.ArcRotateCamera("CameraAAA", Math.PI / 2, Math.PI / 2, 3, new BABYLON.Vector3(35,3.5,35), _this.scene2 );
                camera.setTarget( new BABYLON.Vector3(0,0,0));
            }
            else 
            {
                //2、第一人称相机
                camera = new BABYLON.FreeCamera("FreeCamera", new BABYLON.Vector3(5, 2.1, 15), _this.scene2 );
                camera.setTarget( new BABYLON.Vector3(11.25,1.5,19));
            } 

            camera.attachControl(_this.canvas2, true);
            camera.keysUp.push(87);
            camera.keysDown.push(83);
            camera.keysLeft.push(65);
            camera.keysRight.push(68);
            camera.speed = 0.5;
            camera.inertia = 0.8;
            camera.minZ = 0.05;
            camera.applyGravity = true;
            camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
            // 开启碰撞检测
            _this.scene2.collisionsEnabled = true;
            camera.checkCollisions = true;

            _this.scene2 .activeCamera = camera;  // 可以解决多个相机出错的问题
            console.log("showmodel====改变了val=====:",val);
            console.log("showmodel====改变了=====:",_this.showmodel2);
        },
    } , 
    methods:{ 
        click:function(){
            console.log("showmodel====================:",_this.showmodel2);
            this.clickevent("Babylon return");
        }
    }, 
} 
</script>

<style>
#renderCanvas {
    width   : 100%;
    height  : 100%;
    touch-action: none;
}
</style>
